<template>
  <div v-if="!mobile" class="my-4">
    <v-row class="mb-5">
      <div style="position: relative; left: -50px; height: 40px; width: 40px; border-radius: 50%;" class="background-color-primary-shade6"/>
      <h1 style="position: relative; left: -40px;align-self: center;" class="color-primary">{{ title }}</h1>
    </v-row>
    <v-row justify="center">
      <v-col class="col-lg-4 col-sm-12" v-for="(item, index) in items">
        <opinion
            :user="item.user"
            :date="item.date"
            :content="item.content"
            :key="index"
        />
      </v-col>
    </v-row>
  </div>
  <div v-else>
    <v-row justify="center" class="mb-5">
      <h3 class="color-primary centered-h3">{{ title }}</h3>
    </v-row>
    <carousel :height="400">
      <v-carousel-item v-for="(item, index) in items" :key="index">
        <v-row justify="center">
          <v-col class="col-8">
            <opinion
                :user="item.user"
                :date="item.date"
                :content="item.content"
            />
          </v-col>
        </v-row>
      </v-carousel-item>
    </carousel>
  </div>
</template>

<script>
import '../../main.css';
import Opinion from "./Opinion";
import Carousel from "../Common/Carousel";

export default {
  name: 'ClientsSection',

  components: {
    Opinion,
    Carousel,
  },

  data: () => ({
    title: 'NUESTROS CLIENTES NOS RESPALDAN',
    items: [
      {
        user: 'pezuca',
        date: '31 Ene 2023 15:29',
        content: 'Hoy hice mi primer transaccion con Velc, la verdad super profesionales, me dieron todo el soporte que necesite dado que era la primera vez que operaba de esta manera, y ademas super expeditivo todo. Mas que recomendable',
      },
      {
        user: 'claudiot',
        date: '24 Ene 2023 18:26',
        content: 'Todo muy bien como siempre. Excelente y muy Recomendable. Saludos!',
      },
      {
        user: 'eldiega',
        date: '07 Dic 2022 11:14',
        content: 'Primera operación que hago con un exchanger y no solo salió todo perfecto y rápido, sino que no deja ninguna duda acerca de la transparencia del proceso. Súper confiables, mil gracias!',
      },
    ],
    mobile: false,
  }),

  mounted() {
    this.mobile = this.isMobile;
  },

  watch: {
    "$store.state.window.mobile"(nv) {
      this.mobile = nv;
    },
  },

  computed: {
    isMobile () {
      return this.$store.state.window.mobile;
    },
  },
}
</script>

<style scoped>
.rectangular-component {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.padding-border {
  padding: 10px;
  border-radius: 10px;
}
.centered-h3 {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.2;
}
</style>