<template>
  <validation-provider
      v-slot="{ errors }"
      rules="required"
      name="checkbox"
  >
    <v-checkbox
        v-model="checkbox"
        :error-messages="errors"
        value="1"
        type="checkbox"
        required
        dense
        outlined
    >
      <template v-slot:label>
        <div>
          Acepta nuestros
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <router-link :to="'/terms'">términos y condiciones</router-link>
            </template>
            .
          </v-tooltip>
        </div>
      </template>
    </v-checkbox>
  </validation-provider>
</template>

<script>

import {ValidationProvider} from "vee-validate";

export default {
  name: 'TermsCheckbox',

  props: {},

  components: {
    ValidationProvider,
  },

  data: () => ({
    checkbox: null,
  }),

  beforeMount() {},

  mounted() {},

  methods: {}
}
</script>