<template>
  <div>
    <validation-provider
        v-slot="{ errors }"
        name="Email"
        :rules="{
                required: required,
                email: true,
                gmailValidation: 'gmail',
                hotmailValidation: 'hotmail',
                liveValidation: 'live',
                outlookValidation: 'outlook',
                yahooValidation: 'yahoo',
              }"
    >
      <v-text-field
          v-model="field_model"
          :error-messages="errors"
          :label="field_label"
          :append-icon="field_icon"
          required
          outlined
          dense
          @change="onUpdateValue"
          :hint="hint"
      ></v-text-field>
    </validation-provider>
  </div>
</template>

<script>
import {extend, setInteractionMode, ValidationProvider} from 'vee-validate'
import {required, email} from 'vee-validate/dist/rules'

setInteractionMode('eager')

extend('email', {
  ...email,
  message: 'El email debe tener un formato válido',
})

extend('required', {
  ...required,
  message: 'Este campo es requerido',
})

extend('yahooValidation', {
  validate(value, {str}) {
    let start = value.indexOf("@") + 1;
    let end = value.indexOf(".com");
    let mailer = value.substring(start, end)

    let literal = [...new Set(str.split('').sort().join('').toLowerCase())].join('');
    let compare = [...new Set(mailer.split('').sort().join('').toLowerCase())].join('');

    return !((str !== mailer) && (literal === compare));
  },
  params: ['str'],
  message: 'Quizás quisiste escribir @{str}',
})

extend('outlookValidation', {
  validate(value, {str}) {
    let start = value.indexOf("@") + 1;
    let end = value.indexOf(".com");
    let mailer = value.substring(start, end)

    let literal = [...new Set(str.split('').sort().join('').toLowerCase())].join('');
    let compare = [...new Set(mailer.split('').sort().join('').toLowerCase())].join('');

    return !((str !== mailer) && (literal === compare));
  },
  params: ['str'],
  message: 'Quizás quisiste escribir @{str}',
})

extend('liveValidation', {
  validate(value, {str}) {
    let start = value.indexOf("@") + 1;
    let end = value.indexOf(".com");
    let mailer = value.substring(start, end)

    let literal = [...new Set(str.split('').sort().join('').toLowerCase())].join('');
    let compare = [...new Set(mailer.split('').sort().join('').toLowerCase())].join('');

    return !((str !== mailer) && (literal === compare));
  },
  params: ['str'],
  message: 'Quizás quisiste escribir @{str}',
})

extend('gmailValidation', {
  validate(value, {str}) {
    let start = value.indexOf("@") + 1;
    let end = value.indexOf(".com");
    let mailer = value.substring(start, end)

    let literal = [...new Set(str.split('').sort().join('').toLowerCase())].join('');
    let compare = [...new Set(mailer.split('').sort().join('').toLowerCase())].join('');

    return !((str !== mailer) && (literal === compare));
  },
  params: ['str'],
  message: 'Quizás quisiste escribir @{str}',
})

extend('hotmailValidation', {
  validate(value, {str}) {
    let start = value.indexOf("@") + 1;
    let end = value.indexOf(".com");
    let mailer = value.substring(start, end)

    let literal = [...new Set(str.split('').sort().join('').toLowerCase())].join('');
    let compare = [...new Set(mailer.split('').sort().join('').toLowerCase())].join('');

    return !((str !== mailer) && (literal === compare));
  },
  params: ['str'],
  message: 'Quizás quisiste escribir @{str}',
})

export default {
  name: "ValidatedMailInput",

  components: {
    ValidationProvider,
  },

  props: {
    label: String,
    model: String,
    required: Boolean,
    important: Boolean,
    hint: String,
  },

  data: () => ({
    field_label: "",
    field_model: "",
    field_icon: '',
  }),

  mounted() {
    this.field_label = this.label;
    this.field_model = this.model;
    if(this.important) {
      this.field_icon = 'mdi-exclamation-thick';
    }
  },

  methods: {
    onUpdateValue: function(payload) {
      this.$emit('change', payload)
    },
  },
}
</script>