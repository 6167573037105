<template>
  <validation-observer
      ref="observer"
      v-slot="{ invalid }"
  >
    <div v-if="!mobile">
      <form @submit.prevent="submit">
        <v-row class="mb-5">
          <div style="position: relative; left: -50px; height: 40px; width: 40px; border-radius: 50%;" class="background-color-primary-shade6"/>
          <h1 style="position: relative; left: -40px;align-self: center;" class="color-primary">{{ title }}</h1>
        </v-row>
        <v-row>
          <v-col class="full-height align-content-center align-center">
            <validated-filled-text-input @change="setContactName" label="Nombre y apellido" required />
            <validated-filled-mail-input @change="setContactMail" label="Mail registrado" required/>
            <validated-filled-text-input @change="setContactOrder" label="N° de pedido (opcional)" numeric />
            <validated-filled-text-input @change="setContactMessage" label="Tu consulta" required />
            <v-row class="mb-5">
              <v-col
                  align="end"
                  justify="end">
                <v-btn
                  @click="submitForm"
                  type="submit"
                  color="primary"
                >
                  Enviar
                </v-btn>
              </v-col>
            </v-row>
            <v-row justify="center" class="mb-5">
              <v-btn style="background-color: #e6f4eb" href="https://wa.link/10kpkl" target="_blank" rel="noopener noreferrer" >
                <h1 class="color-primary">O ESCRIBINOS POR WHATSAPP</h1>
                <v-icon right size="20" class="color-primary">mdi-whatsapp</v-icon>
              </v-btn>
            </v-row>
          </v-col>
          <v-col class="d-flex text-center">
            <v-img
                contain
                :src="require('../../assets/cellphone.svg')"
                transition="scale-transition"
                height="400"
                width="400"
            />
          </v-col>
        </v-row>
      </form>
    </div>
    <div v-else>
      <form @submit.prevent="submit">
        <div :style="{ backgroundImage: `url(${require('../../assets/icons/background/stains.svg')})` }">
          <v-row justify="center" class="mb-5">
            <h3 style="position: relative; top: 0px;" class="color-primary centered-h3">{{ title }}</h3>
          </v-row>
          <div class="mx-0 mb-5">
            <v-col class="full-height align-content-center align-center">
              <validated-filled-text-input @change="setContactName" label="Nombre y apellido" required />
              <validated-filled-mail-input @change="setContactMail" label="Mail registrado" required/>
              <validated-filled-text-input @change="setContactOrder" label="N° de pedido (opcional)" numeric />
              <validated-filled-text-input @change="setContactMessage" label="Tu consulta" required />
              <v-col
                  align="end"
                  justify="end">
                <v-btn
                    @click="submitForm"
                    type="submit"
                    color="primary"
                >
                  Enviar
                </v-btn>
                <!--<captcha-button/>-->
                <!--<v-btn @click="submitForm" type="submit" color="primary">Submit</v-btn>-->
              </v-col>
            </v-col>
          </div>
          <v-row justify="center" class="mb-5">
            <v-btn style="background-color: #e6f4eb" href="https://wa.link/10kpkl" target="_blank" rel="noopener noreferrer" >
              <h3 class="color-primary">O ESCRIBINOS POR WHATSAPP</h3>
              <v-icon right size="20" class="color-primary">mdi-whatsapp</v-icon>
            </v-btn>
          </v-row>
        </div>

      </form>

    </div>
  </validation-observer>
</template>

<script>
import '../../main.css';
import {required} from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import {default as axios} from "axios";
import ValidatedFilledTextInput from "../Form/ValidatedFilledTextInput";
import ValidatedFilledMailInput from "../Form/ValidatedFilledMailInput";
import Swal from "sweetalert2";
import router from "../../router";
import {load} from "recaptcha-v3";
import CaptchaButton from "../Common/CaptchaButton";

setInteractionMode('eager')

export default {
  name: 'ContactSection',

  components: {
    ValidatedFilledTextInput,
    ValidatedFilledMailInput,
    ValidationProvider,
    ValidationObserver,
    CaptchaButton,
  },

  data: () => ({
    title: 'CONTACTANOS',
    contactName: null,
    contactMail: null,
    contactOrder: null,
    contactMessage: null,
    contactAppl: null,
    mobile: false,
  }),

  methods: {
    submit() {this.$refs.observer.validate();},
    setContactName(payload) {this.contactName = payload ?? null;},
    setContactMail(payload) {this.contactMail = payload ?? null;},
    setContactOrder(payload) {this.contactOrder = payload ?? null;},
    setContactMessage(payload) {this.contactMessage = payload ?? null;},

    submitForm () {
      this.recaptcha.execute('contact_form').then((token) => {

        this.contactAppl = {
          ...(this.contactName != null && {"name": this.contactName}),
          ...(this.contactMail != null && {"email": this.contactMail}),
          ...(this.contactOrder != null && {"order_id": this.contactOrder}),
          ...(this.contactMessage != null && {"message": this.contactMessage}),
          ...{"captcha_token": token},
        };

        Swal.fire({
          title: 'Procesando...',
          html: 'Enviando consulta.',
          allowOutsideClick: false,
          scrollbarPadding: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });

        axios
            .post(process.env.VUE_APP_API_URL + '/api/newsletter', this.contactAppl)
            .then(response => {
              if(response.status === 200) {
                Swal.close();
                Swal.fire({
                  title: '¡Gracias por enviarnos tu consulta!',
                  html: 'Nos pondremos en contacto con vos muy pronto.',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 5000,
                  scrollbarPadding: false,
                });
              } else {
                Swal.fire({
                  title: '¡Oops!',
                  html: 'Hubo un error al procesar tu pedido. Por favor, intentá de nuevo.',
                  icon: 'error',
                  showConfirmButton: false,
                  timer: 5000,
                  scrollbarPadding: false,
                });
              }
            })
            .catch(e => {
              Swal.fire({
                title: '¡Oops!',
                html: 'Hubo un error al procesar consulta. Por favor, intentá de nuevo.',
                icon: 'error',
                showConfirmButton: false,
                timer: 5000,
                scrollbarPadding: false,
              });
            });
      })
    },
  },

  mounted() {
    load(process.env.VUE_APP_GOOGLE_CAPTCHA).then((recaptcha) => this.recaptcha = recaptcha);
    this.mobile = this.isMobile;
  },

  watch: {
    "$store.state.window.mobile"(nv) {
      this.mobile = nv;
    },
  },

  computed: {
    isMobile () {
      return this.$store.state.window.mobile;
    },
  },
}
</script>
<style>
.centered-h3 {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.2;
}
</style>