import Vue from 'vue'
import Vuex from 'vuex'
import Rate from "../model/Rate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    active: true,

    rates_loading: true,
    municipalities: [],

    shipmentTypes: [],
    shipmentTypeList: [],
    rate_id: null,
    rate_price: null,

    not_valid_amounts: true,

    address: null,
    address_receiver: null,
    address_phone: null,

    withdrawal_date: null,

    address_lng: null,
    address_lat: null,
    address_price: null,
    address_comments: null,

    window: {
      width: 0,
      height: 0,
      mobile: false,
    },

    rates: [],
    systems: [],
    calendar: [],
    dates: [],
    selected_rate: null,
    send_amount: null,
    receive_amount: null,
    offices: [],
    shipment_type_list: [],
    valid_amounts: true,
    office: 1,
    referral_code: null,
    account_data: null,
    account_type: null,
  },
  mutations: {
    updateStoreValue(state, { variable, value }) {
      state[variable] = value;
    },

    setShipmentTypes (state, param) {
      state.shipmentTypes = [];

      for (let i = 0; i < param.length; i++) {
        state.shipmentTypes.push(param[i]);
      }
    },

    setShipmentTypeList (state, param) {
      state.shipmentTypeList = param;
    },

    setRateId(state, param) {
      state.rate_id = param;
    },

    setRatePrice(state, param) {
      state.rate_price = param;
    },

    setSendAmount(state, param) {
      state.send_amount = param;
    },

    setReceiveAmount(state, param) {
      state.receive_amount = param;
    },

    setSelectedShipmentType(state, param) {
      state.selectedShipmentType = param;
    },

    setActive(state, param) {
      state.active = param;
    },

    setRates(state, param) {
      state.rates = param;
      state.rates_loading = false;
    },

    setMunicipalities(state, param) {
      state.municipalities = param;
    },

    setCalendar(state, param) {
      state.calendar = param;
    },

    setNotValidAmounts(state, param) {
      state.not_valid_amounts = param;
    },

    setWindowWidth(state, param) {
      state.window.width = param;
    },

    setWindowHeight(state, param) {
      state.window.height = param;
    },

    setWindowMobile(state, param) {
      state.window.mobile = param;
    },

    setReceiverPhone(state, param) {
      state.address_phone = param;
    },

    setAddress(state, param) {
      state.address = param;
    },

    setAddressReceiver(state, param) {
      state.address_receiver = param;
    },

    setAddressComments(state, param) {
      state.address_comments = param;
    },

    setAddressLat(state, param) {
      state.address_lat = param;
    },

    setAddressLng(state, param) {
      state.address_lng = param;
    },

    setAddressPrice(state, param) {
      state.address_price = param;
    },

    setWithdrawalDate(state, param) {
      state.withdrawal_date = param;
    },

  },
  actions: {
  },
  modules: {
  }
})
