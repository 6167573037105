<template>
  <v-scale-transition>
    <v-card class="pa-5 rounded-lg" outlined >
      <div>
        Seleccionar una fecha y hora para el retiro
      </div>
      <v-dialog
          ref="dialog"
          v-model="menu1"
          :return-value.sync="date"
          persistent
          width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <validation-provider
              v-slot="{ errors }"
              :rules="{required: true,}"
          >
            <v-text-field
                v-model="formattedDate"
                label="Hacé click para seleccionar fecha y hora"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </validation-provider>
        </template>
        <v-date-picker
            v-model="date"
            :show-current="true"
            @input="onChangeDate(date)"
            :allowed-dates="allowedDates"
            scrollable
        ></v-date-picker>
      </v-dialog>
      <v-dialog
          ref="dialog"
          v-model="menu2"
          :return-value.sync="time"
          persistent
          width="290px"
      >
        <v-time-picker
            v-model="time"
            :allowed-minutes="allowedStep"
            scrollable
            @change="onChangeTime(time)"
            format="24hr"
            :min="minHour"
            :max="maxHour"
            :key="componentKey"
        ></v-time-picker>
      </v-dialog>
    </v-card>
  </v-scale-transition>
</template>

<script>

import {extend, ValidationProvider} from 'vee-validate'
import {required} from "vee-validate/dist/rules";

extend('required', {
  ...required,
  message: 'Este campo es requerido',
})

export default {
  name: "WithdrawForm",

  components: {
    ValidationProvider,
  },

  props: {},

  data: () => ({
    date: null,
    formattedDate: null,
    searchDate: null,
    time: null,
    menu1: false,
    menu2: false,

    now: null,

    allowed: [],

    componentKey: 0,
  }),

  beforeMount() {
    this.now = new Date(Date.now());
    this.calendar.map(x => this.allowed.push([x['date'].substring(0, 10),x['start_time'],x['end_time']]));
  },

  methods: {
    allowedDates(v) {
      return this.arrayHasElement(this.allowed, v.substring(0, 10));
    },

    allowedStep: m => m % 15 === 0,

    onChangeDate(date) {
      this.formattedDate = this.formatDate(date);
      this.searchDate = date;
      this.time = null;
      this.menu1 = false;
      this.menu2 = true;
    },

    onChangeTime(time) {
      this.formattedDate = this.formattedDate + ' ' + time + ':00';
      this.$store.commit('setWithdrawalDate', this.formattedDate);
      this.componentKey++;
      this.menu2 = false;
    },

    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

    arrayHasElement(array, element) {
      return array.filter(e => e[0] === element).length > 0;
    },

    getArrayElement(array, element) {
      return array.find(e => e[0] === element);
    },

    todaySelected() {
      return this.allowed[0][0] === this.searchDate;
    },

    maxOf(now, selectedDayStartTime) {
      let startTime = selectedDayStartTime.substring(0, 5).replaceAll(':', '');
      if (startTime.length === 3) {
        startTime = startTime + '0';
      }

     return Math.max(parseInt(now), parseInt(startTime));
    }
  },

  computed: {
    calendar () {return this.$store.state.calendar;},

    defaultTime () {return new Date(new Date().toLocaleString('en', {timeZone: 'America/Argentina/Buenos_Aires'}));},

    minHour  () {
      let object = this.getArrayElement(this.allowed, this.searchDate);
      if(object) {

        let minTime;

        if(this.todaySelected()) {
          minTime = this.maxOf((this.defaultTime.getHours() * 100 + this.defaultTime.getMinutes()).toString(), object[1]);
        } else {
          minTime = object[1].substring(0, 5).replaceAll(':', '');
          if (minTime.length === 3) {
            minTime = minTime + '0';
          }
        }

        return minTime.toString().substring(0, 2) + ':' + minTime.toString().substring(2, 4);
      }

      return '11:0';
    },

    maxHour() {
      let object = this.getArrayElement(this.allowed, this.searchDate);
      if(object) {
        let maxTime = object[2].substring(0, 5).replaceAll(':', '');
        if (maxTime.length === 3) {
          maxTime = maxTime + '0';
        }
        return maxTime.toString().substring(0, 2) + ':' + maxTime.toString().substring(2, 4);
      }
      return '15:29';
    },
  }
}
</script>