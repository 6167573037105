<template>
  <div v-if="!mobile" class="my-4 mt-6">
    <div class="padding-border background-color-primary-shade5">
      <div>
        <h1 style="align-self: center;" class="color-primary">{{ title }}</h1>
      </div>
      <div class="rectangular-component d-flex text-center my-10">
        <v-row justify="center">
          <v-col class="col-xl-2 col-lg-2 col-sm-12" v-for="(item, index) in items">
            <platform
                :svg-asset="item.svgAsset"
                :key="index"
                :height="70"
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
  <div v-else>
    <v-row justify="center" class="mb-5">
      <h3 class="color-primary centered-h3">{{ title }}</h3>
    </v-row>
    <carousel :height="150">
      <v-carousel-item v-for="(item, index) in items" :key="index">
        <v-row class="d-flex align-center justify-center mx-0">
          <v-col class="col-3" justify="end">
            <platform
                :svg-asset="getPreviousItem(index).svgAsset"
                :key="index"
                :height="40"
            />
          </v-col>
          <v-col class="col-4">
            <platform
                :svg-asset="item.svgAsset"
                :key="index"
                :height="70"
            />
          </v-col>
          <v-col class="col-3">
            <platform
                :svg-asset="getNextItem(index).svgAsset"
                :key="index"
                :height="40"
            />
          </v-col>
        </v-row>
      </v-carousel-item>
    </carousel>
  </div>
</template>

<script>
import '../../main.css';
import Platform from "./Platform";
import Carousel from "../Common/Carousel";

export default {
  name: 'PlatformsSection',

  components: {
    Platform,
    Carousel,
  },

  data: () => ({
    title: 'PLATAFORMAS/MONEDAS QUE USAMOS',
    items: [
      {
        svgAsset: require('../../assets/icons/platforms/money.svg'),
      },
      {
        svgAsset: require('../../assets/icons/platforms/transfer.svg'),
      },
      {
        svgAsset: require('../../assets/icons/platforms/wise.svg'),
      },
      {
        svgAsset: require('../../assets/icons/platforms/payo.svg'),
      },
      {
        svgAsset: require('../../assets/icons/platforms/stablecoins.svg'),
      },
    ],
    mobile: false,
  }),

  mounted() {
    this.mobile = this.isMobile;
  },

  watch: {
    "$store.state.window.mobile"(nv) {
      this.mobile = nv;
    },
  },

  computed: {
    isMobile () {
      return this.$store.state.window.mobile;
    },
  },

  methods: {
    getPreviousItem(index) {
      // Calculate the index of the previous item
      const previousIndex = (index - 1 + this.items.length) % this.items.length;
      return this.items[previousIndex];
    },
    getNextItem(index) {
      // Calculate the index of the next item
      const nextIndex = (index + 1) % this.items.length;
      return this.items[nextIndex];
    }
  },
}
</script>

<style scoped>
.rectangular-component {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.padding-border {
  padding: 10px;
  border-radius: 10px;
}
</style>
<style>
.centered-h3 {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.2;
}
</style>