<template>
  <validation-observer
      ref="observer"
      v-slot="{ invalid }"
  >
    <v-col cols="12">
      <div>
        <br>
        <div class="text-field-header pl-4">
          Vos enviás:
        </div>
        <br>
        <dynamic-card>


          <validation-provider
              v-slot="{ errors }"
              :rules="minMaxClientValidation"
              name="from"
          >
            <v-row
                class="ma-0 pa-0"
                style="position: relative;"
            >
              <!--<v-text-field
                  v-model="amount"
                  :label="systemFrom.currency.abbreviation"
                  type="number"
                  outlined hide-details reverse
                  class="velc-amount-input custom-text-field"
                  style="max-width: 500px"
              >
                <template v-slot:prepend-inner>
                  <v-select
                      flat solo hide-details no-border
                      class="ma-0 pa-0 custom-select"
                      style="width: 300px;"
                      @change="convert"
                      v-model="systemFromId"
                      :items="filteredSystemsFrom"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-img
                          style="height: 80px !important; width: 200px !important; padding: 0 !important;"
                          :src="item.logo_url"
                      ></v-img>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-card class="ma-1 rounded-lg" outlined elevation="0">
                        <v-img
                            :src="item.logo_url"
                        ></v-img>
                      </v-card>
                    </template>
                  </v-select>
                </template>
              </v-text-field>-->




              <!--<dynamic-card class="p-10 custom-card">
                <v-text-field
                    v-model="amount"
                    :label="systemFrom.currency.abbreviation"
                    type="number"
                    outlined hide-details
                    class="velc-amount-input custom-text-field"
                />
                <v-select
                    flat solo hide-details no-border
                    class="ma-0 pa-0 custom-select"
                    @change="convert"
                    v-model="systemFromId"
                    :items="filteredSystemsFrom"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-img
                        :src="item.logo_url"
                    ></v-img>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-card class="ma-1 rounded-lg" outlined elevation="0">
                      <v-img
                          :src="item.logo_url"
                      ></v-img>
                    </v-card>
                  </template>
                </v-select>
              </dynamic-card>-->



              <v-col
                  align="center"
                  cols="12"
                  lg="6"
              >
                <v-text-field
                    filled
                    outlined
                    reverse
                    hide-details
                    @input="populateConvertedAmount"
                    v-model="amount"
                    :label="systemFrom.currency.abbreviation"
                    type="number"
                    class="pa-0 velc-amount-input"
                    :max="systemFrom.max_amount_receive"
                    name="from"
                    :min="systemFrom.min_amount_receive"
                    v-on:blur="handleBlur"
                >
                </v-text-field>
              </v-col>
              <v-col
                  align="center"
                  cols="12"
                  lg="6"
              >
                <v-select
                    outlined
                    class="ma-0 pa-0 velc-select"
                    @change="convert"
                    v-model="systemFromId"
                    :items="filteredSystemsFrom"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-img
                        class="m-0"
                        style="display: flex; align-items: center; height: 76px !important;"
                        :src="item.logo_url"
                    ></v-img>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-card class="my-1 pa-1 rounded-lg" outlined elevation="0">
                      <v-img
                          :src="item.logo_url"
                      ></v-img>
                    </v-card>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <transition name="fade" class="overhead-tip pa-5">
              <div v-if="errors[0]">
                <div class="mx-auto px-3 text-field-error overhead-tip" style="height: 20px;">{{ errors[0] }}</div>
                <div class="triangle-down"></div>
              </div>
            </transition>
            <v-col class="col-12">
              <v-row v-if="systemFrom.tax > 0" justify="center">
                <span style="font-size: 0.875rem"><v-icon class="color-primary" size="15"> mdi-exclamation-thick </v-icon>Recuerde que esta operación contine un costo fijo de {{ systemFrom.tax }} {{ systemFrom.currency.abbreviation }}</span>
              </v-row>
              <v-row v-else justify="center">
                <span style="font-size: 0.875rem"><v-icon class="color-primary" size="15"> mdi-exclamation-thick </v-icon>Esta operación no cuenta con costo fijo</span>
              </v-row>
            </v-col>
          </validation-provider>

        </dynamic-card>
      </div>

      <br>
      <v-row align="center" justify="center">
        <v-icon
            white
            x-large
            center
            class="color-primary"
        >
          mdi-arrow-down
        </v-icon>
      </v-row>
      <br>
      <br>


      <div>
        <div class="text-field-header ml-4">
          Nosotros te enviamos:
        </div>
        <br>
        <dynamic-card>

          <validation-provider
              v-slot="{ errors }"
              :rules="minMaxVelcValidation"
              name="from"
          >
            <v-row
                class="ma-0 pa-0"
            >
              <v-col
                  cols="12"
                  lg="6"
              >
                <v-text-field
                    filled
                    outlined
                    reverse
                    v-model="result"
                    type="number"
                    class="pa-0 velc-amount-input"
                    :label="systemTo.currency.abbreviation"
                    @input="populateUnconvertedAmount"
                    v-on:blur="handleBlur"
                ></v-text-field>
              </v-col>
              <v-col
                  align="center"
                  cols="12"
                  lg="6"
              >

                <v-select
                    outlined
                    class="ma-0 pa-0 velc-select"
                    @change="updateSystemTo"
                    v-model="systemToId"
                    :items="filteredSystemsTo"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-img
                        class="m-0"
                        style="display: flex; align-items: center; height: 76px !important;"
                        :src="item.logo_url"
                    ></v-img>


                  </template>
                  <template v-slot:item="{ item }">
                    <v-card class="my-1 pa-1 rounded-lg" outlined elevation="0">
                      <v-img
                          :src="item.logo_url"
                      ></v-img>
                    </v-card>
                  </template>
                </v-select>


              </v-col>
            </v-row>
            <transition name="fade" class="overhead-tip pa-5">
              <div v-if="errors[0]">
                <div class="mx-auto px-3 text-field-error overhead-tip" style="height: 20px;">{{ errors[0] }}</div>
                <div class="triangle-down"></div>
              </div>
            </transition>
            <v-col class="col-12">
              <v-row justify="center">
                <span style="font-size: 0.875rem; display: flex; align-items: center; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"><v-icon class="color-primary" size="15"> mdi-exclamation-thick </v-icon>{{ example }}</span>
              </v-row>
            </v-col>
          </validation-provider>
        </dynamic-card>
        <br>
        <br>
      </div>
    </v-col>
  </validation-observer>
</template>

<script>
import {required} from 'vee-validate/dist/rules'
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import DynamicCard from '../../components/Common/DynamicCard';
import '../../main.css';
import Swal from "sweetalert2";
import ShipmentType from "../../model/ShipmentType";
import Rate from "../../model/Rate";

extend('req', {
  ...required,
  message: 'El monto a enviar es requerido',
})

extend('positive', {
  validate: value => {
    return value > 0;
  },
  message: 'El monto a enviar debe ser positivo'
});

extend('clientAmountBetween', {
  validate(value, {min, max}) {
    return Number(value) >= Number(min) && Number(value) <= Number(max);
  },
  params: ['min', 'max', 'currency'],
  message: 'Sólo podemos aceptar entre {min} y {max} {currency}'
});

extend('velcAmountBetween', {
  validate(value, {min, max}) {
    return Number(value) >= Number(min) && Number(value) <= Number(max);
  },
  params: ['min', 'max', 'currency'],
  message: 'Sólo podemos enviar entre {min} y {max} {currency}'
});

export default {
  name: 'Calculator',

  props: {
    rates: Array,
    rates2: Array,
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    DynamicCard,
  },


  data: () => ({
    internalRates: null,
    systemFrom: null,
    systemTo: null,
    systemFromId: null,
    systemToId: null,
    amount: 1,
    result: null,
    rate_id: null,
    rate_price: null,
    filteredSystemsTo: [],
    filteredSystemsFrom: [],
    minMaxClientValidation: 'req|positive',
    minMaxVelcValidation: 'req|positive',
    notValidAmounts: false,
    selectedShipmentType: null,
    example: '',
  }),

  methods: {
    convert() {
      this.populateSystemFrom();
      this.filterSystemsToSelect();
      this.updateSystemTo();
    },

    updateSystemTo() {
      this.populateSystemTo();
      this.addMinMaxValidations();
      this.populateAmount();
      this.populateConvertedAmount();
      this.updateStore();
    },

    updateStore() {
      this.$store.commit('setShipmentTypes', this.systemTo.shipments_type);

      this.shipmentTypeList = this.systemTo.shipments_type.map(type => new ShipmentType(type));
      this.$store.commit('setShipmentTypeList', this.shipmentTypeList);

      this.$store.commit('setRateId', this.rate_id);
      this.$store.commit('setRatePrice', this.rate_price);
      this.$store.commit('setReceiveAmount', this.amount);
      this.$store.commit('setSendAmount', this.result);
    },

    filterSystemsToSelect() {
      this.filteredSystemsTo = [];

      for (let i = 0; i < this.systemFrom.rates.length; i++) {
        if (this.systemFrom.rates[i].system_to_id !== this.systemFrom.value) {
          let rate = this.rates.find(element => element.value === this.systemFrom.rates[i].system_to_id);

          if (this.systemFrom.rates[i].system_to.can_send === true) {
            this.filteredSystemsTo.push(rate);
          }
        }
      }

      this.systemToId = this.systemFrom.rates[0].system_to_id;
      this.systemTo = this.rates.find(element => element.value === this.systemToId);
    },

    filterSystemsFromSelect(rates) {
      this.filteredSystemsFrom = [];

      for (let i = 0; i < rates.length; i++) {
        if ((rates[i].can_receive === true) && (rates[i].rates.length > 0)) {
          this.filteredSystemsFrom.push(rates[i]);
        }
      }

      if (this.systemFrom == null) {
        this.systemFromId = this.filteredSystemsFrom[0].value;
      }

      this.systemFrom = this.rates.find(element => element.value === this.systemFromId);
    },

    populateSystemFrom() {
      this.systemFrom = this.rates.find(element => element.value === this.systemFromId);
    },

    populateSystemTo() {
      this.systemTo = this.rates.find(element => element.value === this.systemToId);
    },

    populateConvertedAmount() {
      let rate = this.systemFrom.rates.find(element => element.system_to_id === this.systemToId);

      let rateObject = new Rate(rate, this.systemFrom.value)
      this.$store.commit('updateStoreValue', { variable: 'selected_rate', value: rateObject });

      this.rate_id = rate.id;
      this.rate_price = rate.price;

      this.result = this.convertPrice(this.amount, rate);

      this.notValidAmounts = !this.validateInput(rate);
      this.$store.commit('setRateId', this.rate_id);
      this.$store.commit('setRatePrice', this.rate_price);
      this.$store.commit('setReceiveAmount', this.amount);
      this.$store.commit('setSendAmount', this.result);
      this.$store.commit('setNotValidAmounts', this.notValidAmounts);

      let system = this.systemFrom.rates.find(element => element.system_to_id === this.systemToId)
      let ratePrice = Number(system.type) === 1 ? this.rate_price : (1 / this.rate_price);
      let exampleAmount = ratePrice >= 100 ? 100 : Math.round(100 / ratePrice * 100) / 100;
      let convertedAmount = Math.round(exampleAmount * ratePrice * 100) / 100;
      this.example= 'Vos enviás ' + exampleAmount + ' ' + this.systemFrom.currency.abbreviation + ' y recibís ' + convertedAmount + ' ' + this.systemTo.currency.abbreviation;
    },

    populateUnconvertedAmount() {
      let rate = this.systemFrom.rates.find(element => element.system_to_id === this.systemToId);

      this.rate_id = rate.id;
      this.rate_price = rate.price;

      this.amount = this.unConvertPrice(this.result, rate);

      this.notValidAmounts = !this.validateInput(rate);
      this.$store.commit('setRateId', this.rate_id);
      this.$store.commit('setRatePrice', this.rate_price);
      this.$store.commit('setReceiveAmount', this.amount);
      this.$store.commit('setSendAmount', this.result);
      this.$store.commit('setNotValidAmounts', this.notValidAmounts);
    },

    populateAmount() {
      if (this.amount < this.systemFrom.min_amount_receive) {
        this.amount = this.systemFrom.min_amount_receive;
      }

      let rate = this.systemFrom.rates.find(element => element.system_to_id === this.systemToId);

      if((this.systemTo.round_precision != null)
          && (this.systemTo.round_precision > null)
          && ((this.convertPrice(this.amount, rate) % this.systemTo.round_precision) !== 0)) {

        this.result = ~~(this.convertPrice(this.amount, rate) / this.systemTo.round_precision) * this.systemTo.round_precision + this.systemTo.round_precision;

        this.amount = this.unConvertPrice(this.result, rate);

        this.notValidAmounts = !this.validateInput(rate);
        this.$store.commit('setNotValidAmounts', this.notValidAmounts);
      } else {
        this.result = this.convertPrice(this.amount, rate);
      }
    },

    addMinMaxValidations() {
      let rate = this.systemFrom.rates.find(element => element.system_to_id === this.systemToId);
      let clientMin, clientMax, velcMin, velcMax;

      if (this.convertPrice(this.systemFrom.min_amount_receive, rate) > Number(rate.system_to.min_amount_send)) {
        clientMin = this.systemFrom.min_amount_receive;
      } else {
        clientMin = this.unConvertPrice(rate.system_to.min_amount_send, rate);
      }
      velcMin = this.convertPrice(clientMin, rate);

      this.amount = clientMin;

      if (this.convertPrice(this.systemFrom.max_amount_receive, rate) < Number(rate.system_to.max_amount_send)) {
        clientMax = this.systemFrom.max_amount_receive;
      } else {
        clientMax = this.unConvertPrice(rate.system_to.max_amount_send, rate);
      }
      velcMax = this.convertPrice(clientMax, rate);

      this.minMaxClientValidation = 'req|positive|clientAmountBetween:' + clientMin + ',' + clientMax + ',' + this.systemFrom.currency.abbreviation;
      this.minMaxVelcValidation = 'req|positive|velcAmountBetween:' + velcMin + ',' + velcMax + ',' + this.systemTo.currency.abbreviation;
    },

    validateInput(rate) {
      return Number(this.amount) >= Number(this.systemFrom.min_amount_receive)
          && Number(this.amount) <= Number(this.systemFrom.max_amount_receive)
          && (Number(this.result) >= Number(rate.system_to.min_amount_send))
          && (Number(this.result) <= Number(rate.system_to.max_amount_send))
          && ((this.result % this.systemTo.round_precision) !== 0);
    },

    convertPrice(amount, rate) {
      let amountAfterTaxes = amount - this.systemFrom.tax;
      let option = rate.type;
      if (Number(option) === 1) {
        return Math.round(amountAfterTaxes * rate.price * 100) / 100;
      } else {
        return Math.round(amountAfterTaxes / rate.price * 100) / 100;
      }
    },

    unConvertPrice(amount, rate) {
      let option = rate.type;
      if (Number(option) === 1) {
        return (Math.round(amount / rate.price * 100) / 100) + this.systemFrom.tax;
      } else {
        return (Math.round(amount * rate.price * 100) / 100) + this.systemFrom.tax;
      }
    },

    handleBlur() {
      let rate = this.systemFrom.rates.find(element => element.system_to_id === this.systemToId);

      if(
          (this.systemTo.round_precision > 0)
          && ((this.result % this.systemTo.round_precision) !== 0)
          && this.validateInput(rate)
      ) {

        let rounded_down_result = ~~(this.result / this.systemTo.round_precision) * this.systemTo.round_precision;
        let rounded_up_result = ~~(this.result / this.systemTo.round_precision) * this.systemTo.round_precision + this.systemTo.round_precision;
        let rounded_down_option = this.unConvertPrice(rounded_down_result, rate);
        let rounded_up_option = this.unConvertPrice(rounded_up_result, rate);

        Swal.fire({
          title: 'Oops! Tenemos que pedirte algo...',
          html: 'Sólo podemos darte múltiplos de ' + this.systemTo.round_precision + ' en la moneda seleccionada. Necesitamos redondear el monto ingresado a ' + rounded_down_option.toString() + ' o a ' + rounded_up_option.toString() + '. Por favor, elegí una de las opciones.',
          showDenyButton: true,
          confirmButtonText: rounded_down_option.toString(),
          denyButtonText: rounded_up_option.toString(),
          allowOutsideClick: false,
          scrollbarPadding: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.amount = rounded_down_option;
            this.result = rounded_down_result;
            this.$store.commit('setReceiveAmount', this.amount);
            this.$store.commit('setSendAmount', this.result);
          } else if (result.isDenied) {
            this.amount = rounded_up_option;
            this.result = rounded_up_result;
            this.$store.commit('setReceiveAmount', this.amount);
            this.$store.commit('setSendAmount', this.result);
          }
        })
      }
    },
    handleFocusout(e) {
      console.log('focusout', e.target.placeholder);
    }

  },

  mounted() {

  },

  beforeMount() {
    this.filterSystemsFromSelect(this.rates);
    this.systemFromId = this.filteredSystemsFrom[0].value;
    this.systemFrom = this.rates.find(element => element.value === this.systemFromId);

    this.rates = this.getRates;
    this.convert();
  },

  watch: {
    "$store.state.rates"(nv) {
      this.filterSystemsFromSelect(nv);
      //this.systemFromId = this.filteredSystemsFrom[0].value;
      this.populateConvertedAmount();
      this.updateStore();
    },
  },

  computed: {
    getRates () {
      return this.$store.state.rates;
    },
  },
}
</script>
<style>

.custom-text-field .v-text-field__input {
  text-align: right;
}

.custom-text-field .v-label--active {
  position: relative;
  top: -15px;
  left: 10px !important;
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
}

.custom-text-field {
  height: 100px !important;
}

.custom-text-field .v-input__control {
  height: 100%;
}

.custom-select {
  display: flex;
  align-items: center;
  height: 90px !important;
}

.custom-select .v-select__slot {
  height: 100%;
}

.custom-select .v-select__selections {
  height: 100%;
  display: flex;
  align-items: center;
}

.custom-text-field v-input__append-inner {
  padding: 0 !important;
}

.custom-select .v-select__selection {
  flex-grow: 1;
}

.custom-select .v-select__selections .v-select__selection .v-image {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.custom-card {
  transition: box-shadow 0.3s; /* Add a smooth transition effect */
  border: none; /* Remove the default border */
}

.custom-card:active {
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.2); /* Apply a different box-shadow on hover */
}

.custom-text-field.v-text-field--outlined .v-label {
  color: green; /* Change the line color to green */
}

.custom-text-field.v-text-field--outlined .v-input__control:before {
  background-color: green; /* Change the line color to green */
}

.custom-text-field.v-text-field--outlined .v-input__control:after {
  background-color: green; /* Change the line color to green */
}

.v-input input {
  max-height: 64px;
}

.v-select.v-text-field--outlined:not(.v-text-field--single-line) .v-select__selections {
  padding: 2px 0;
}
</style>