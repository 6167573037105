<template>
  <div :style="containerStyles" class="px-5">
    <h2 :style="textStyles" class="color-primary">{{ text }}</h2>
  </div>
</template>

<script>
import {integer} from "vee-validate/dist/rules";

export default {
  name: 'RoundedContainer',

  props: {
    color: {
      type: String,
      default: 'var(--color-primary-shade5)'
    },

    borderRadius: {
      type: String,
      default: '16px'
    },

    margin: {
      type: String,
      default: '10px'
    },

    padding: {
      type: String,
      default: '10px'
    },

    text: {
      type: String,
      required: true,
    },
  },

  computed: {
    containerStyles() {
      return {
        backgroundColor: this.color,
        borderRadius: this.borderRadius,
        margin: this.margin,
        padding: this.padding,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }
    },

    textStyles() {
      return {
        alignSelf: 'center',
      }
    }
  }
}
</script>