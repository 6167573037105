export default class ShipmentData {
    constructor(data) {
        this.address = data.address || '';
        this.addressComments = data.address_details || '';
        this.addressReceiver = data.address_receiver || '';
        this.addressPhone = data.address_phone || '';
        this.addressLat = data.address_lat || '';
        this.addressLng = data.address_lng || '';
        this.addressPrice = data.address_price || '';
        this.withdrawalDate = data.withdrawal_date || '';
    }

    static Blank() {
        return new ShipmentData({
            address: '',
            address_details: '',
            address_receiver: '',
            address_phone: '',
            address_lat: '',
            address_lng: '',
            address_price: '',
            withdrawal_date: '',
        });
    }
}