export default class OrderData {
    constructor(rate, sendAmount, receiveAmount, localUserData, localShipmentData, selectedShipment, office, referralCode, accountData) {
        this.rate_id = rate.id;
        this.rate_price = rate.price;
        this.send_amount = Number(sendAmount).toFixed(2);
        this.receive_amount = Number(receiveAmount).toFixed(2);
        this.shipment_id = selectedShipment.id;

        this.client_name = localUserData.clientName;
        this.whatsapp = localUserData.phone;
        this.client_mail = localUserData.clientMail;

        this.transfer_bank = this.shipment_id === 2 ? accountData.transferBank : null;
        this.transfer_holder = this.shipment_id === 2 ? accountData.transferHolder : null;
        this.transfer_alias = this.shipment_id === 2 ? accountData.transferAlias : null;
        this.transfer_cuit = this.shipment_id === 2 ? accountData.transferCuit : null;
        this.transfer_cbu = this.shipment_id === 2 ? accountData.transferCbu : null;
        this.transfer_account_number = this.shipment_id === 2 ? accountData.transferAccountNumber : null;
        this.wallet_adress = this.shipment_id === 4 ? accountData.type + ': ' + accountData.walletAddress : null;

        this.account_email = this.shipment_id === 3 ? accountData.accountEmail : null;

        this.address = this.shipment_id === 1 ? localShipmentData.address + ' - ' + localShipmentData.addressComments : null;
        this.address_receiver = this.shipment_id === 1 ? localShipmentData.addressReceiver : null;
        this.address_telephone = this.shipment_id === 1 ? localShipmentData.addressPhone : null;
        this.address_lat = this.shipment_id === 1 ? localShipmentData.addressLat : null;
        this.address_lng = this.shipment_id === 1 ? localShipmentData.addressLng : null;
        this.address_price = this.shipment_id === 1 ? localShipmentData.addressPrice : null;

        this.withdrawal_datetime = localShipmentData.withdrawalDate;

        this.region = office;
        this.client_referral_code = referralCode;
    }
}