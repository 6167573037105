<template>
  <div>
    <dynamic-container class="background-color-primary-shade6 pb-10">
      <v-container fluid class="full-height">
        <v-scale-transition>
          <div v-if="!loading">
              <dynamic-row>
                <dynamic-column>
                  <dynamic-card-column>
                    <amount-form :rate-list="rateList" :system-list="systemList" />
                  </dynamic-card-column>
                </dynamic-column>
                <dynamic-column>
                  <stepper v-if="active" elevation="6" class="rounded-lg" :user-data="userData" :shipment-data="shipmentData" :accounts-data="userAccountsData" :key="redrawKey" />
                  <div v-else align="center">
                    <dynamic-card-column>
                      <div class="text-header-1 pa-5">
                        En este momento no podemos atender tu pedido
                      </div>
                      <div class="text-subheader px-5">
                        Nuestro horario de atención es de lunes a viernes de 11 a 15:30 hs. No tomamos pedidos fuera de ese horario, pero dejamos disponible la calculadora para que puedas analizar el cambio con los últimos valores disponibles.
                      </div>
                      <br><br>
                    </dynamic-card-column>
                  </div>
                </dynamic-column>
              </dynamic-row>
            <v-row justify="center" >
              <div style="position: relative; bottom: -60px;">
                <v-card style="border-radius: 50%;" elevation="5">
                  <v-icon
                      white
                      x-large
                      center
                      class="color-primary"
                  >
                    mdi-chevron-double-down
                  </v-icon>
                </v-card>
              </div>
            </v-row>
          </div>
        </v-scale-transition>
        <v-scale-transition>
          <loader v-if="loading"/>
        </v-scale-transition>
      </v-container>
    </dynamic-container>
    <div class="mt-10" v-if="!loading">
      <v-scale-transition>
        <dynamic-container>
          <v-row justify="center" class="mx-0 fill-height" >
            <dynamic-column aspect_ratio="full">
              <services-section/>
            </dynamic-column>
          </v-row>
        </dynamic-container>
      </v-scale-transition>

      <dynamic-container v-bind:class="{ 'background-color-primary-shade5': mobile }">
        <v-scale-transition>
          <v-row justify="center" class="mt-4 mx-0 fill-height" >
            <dynamic-column aspect_ratio="full">
              <platforms-section/>
            </dynamic-column>
          </v-row>
        </v-scale-transition>
      </dynamic-container>

      <dynamic-container>
        <v-scale-transition>
          <v-row justify="center" class="mt-10 mx-0 fill-height" >
            <dynamic-column aspect_ratio="full">
              <clients-section/>
            </dynamic-column>
          </v-row>
        </v-scale-transition>
      </dynamic-container>

      <dynamic-container>
        <v-scale-transition>
          <v-row justify="center" class="mt-10 mx-0 fill-height" >
            <dynamic-column aspect_ratio="full">
              <contact-section/>
            </dynamic-column>
          </v-row>
        </v-scale-transition>
      </dynamic-container>
    </div>
  </div>
</template>

<script>
  import Stepper from '../components/Stepper/Stepper';
  import RatesTable from '../components/Rates/RatesTable';
  import RatesMatrix from '../components/Rates/RatesMatrix';
  import Loader from '../components/Common/Loader';
  import DynamicCardColumn from '../components/Common/DynamicCardColumn';
  import DynamicContainer from '../components/Common/DynamicContainer';
  import DynamicRow from '../components/Common/DynamicRow';
  import DynamicColumn from '../components/Common/DynamicColumn';
  const axios = require('axios').default;
  import Calculator from "../components/Calculator/Calculator";
  import ServicesSection from '../components/Services/ServicesSection';
  import ContactSection from '../components/Contact/ContactSection';
  import PlatformsSection from '../components/Platforms/PlatformsSection';
  import '../main.css';
  import ClientsSection from "../components/Clients/ClientsSection";
  import UserData from "../model/UserData";
  import CalendarItem from "../model/CalendarItem";
  import System from "../model/System";
  import Rate from "../model/Rate";
  import AmountForm from "../components/amounts/AmountForm";
  import Office from "../model/Office";
  import ShipmentData from "../model/ShipmentData";
  import Swal from "sweetalert2";
  import AccountData from "../model/AccountData";

  export default {
    name: 'Home',

    components: {
      Stepper,
      Calculator,
      RatesTable,
      RatesMatrix,
      Loader,
      DynamicCardColumn,
      DynamicRow,
      DynamicColumn,
      ServicesSection,
      ContactSection,
      PlatformsSection,
      ClientsSection,
      DynamicContainer,
      AmountForm,
    },

    props: {
      token: {
        type: String,
        default: null,
      },

      referral_code: {
        type: String,
        default: null,
      },
    },

    data: () => ({
      loading: true,
      active: null,
      mobile: false,
      userData: null,
      shipmentData: null,
      userAccountsData: null,
      redrawKey: 0,

      rateList: {
        default: [],
        validator: function (value) {
          return value.every(item => item instanceof Rate);
        },
      },
      systemList: [],
    }),

    beforeMount() {
      if(this.token != null) {
        this.getUser()
      }

      if(this.referral_code != null) {
        this.$store.commit('updateStoreValue', {variable: 'referral_code', value: this.referral_code });
      }

      this.loading = true
      this.mobile = this.isMobile;

      this.loadRates()
    },

    mounted() {
      window.setInterval(() => {
        this.getRates()
      }, 60000);
    },

    methods: {
      async loadRates() {
        try {
          await this.getRates();
        } catch (error) {
          console.error("Error loading rates:", error);
        }
      },

      getRates() {
        axios
            .get(process.env.VUE_APP_RENDER_URL)
            .then(response => {
              this.processData(response)
            })
            .catch(error => {
              axios
                  .get(process.env.VUE_APP_RENDER_URL)
                  .then(response => {
                    this.processData(response)
                  })
                  .catch(error => {
                    console.log(error)
                  })
            })
      },

      processData(response) {
        this.active = response.data.active;

        this.systemList = System.createSystemsFromArray(response.data.systems)
        this.rateList = Rate.getAllUniqueRates(response.data.rates, this.systemList)
        this.$store.commit('updateStoreValue', { variable: 'rates', value: this.rateList });
        this.$store.commit('updateStoreValue', { variable: 'systems', value: this.systemList });
        this.$store.commit('updateStoreValue', { variable: 'offices', value: response.data.offices.map(officeData => new Office(officeData)) });
        this.$store.commit('updateStoreValue', { variable: 'dates', value: response.data.calendar.map(data => new CalendarItem(data)) });
        this.$store.commit('updateStoreValue', { variable: 'municipalities', value: response.data.municipalities });

        //TODO: si cambia el precio del rate seleccionado, cambiar el monto
        if(this.$store.state.selected_rate !== null) {
          let oldRate = this.$store.state.selected_rate
          let newRate = this.rateList.find(rate => rate.id === oldRate.id)
          if (newRate && oldRate.price !== newRate.price) {
            this.$store.commit('updateStoreValue', {variable: 'selected_rate', value: newRate});
          }
        }

        //TODO: si deja de estar disponible el rate, mostrar un mensaje y recargar toda la calculadora

        this.loading = false;
      },

      getUser() {
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`;

        axios
            .get(process.env.VUE_APP_API_URL + '/api/v1/users/profile')
            .then(response => {
              this.userData = new UserData(response.data.data)
              this.shipmentData = new ShipmentData(response.data.data)
              this.userAccountsData = response.data.data.accounts.map(data => new AccountData(data))

              this.redrawKey++
            })
            .catch(error => {
              console.log(error)
              Swal.fire({
                title: '¡Oops!',
                html: 'El token que estás utilizando ya no es válido. Por favor, ingresá nuevamente desde el panel de administración.',
                icon: 'error',
                showConfirmButton: false,
                scrollbarPadding: false,
              });
            })
      }
    },

    watch: {
      "$store.state.window.mobile"(nv) {
        this.mobile = nv;
      },
    },

    computed: {
      isMobile () {
        return this.$store.state.window.mobile;
      },
    },
  }
</script>
<style>
  @media only screen and (max-width: 959px) {
    .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
      display: flex !important;
    }
  }
</style>