<template>
  <v-scale-transition>
    <v-card class="pa-5 rounded-lg" outlined >
      <div>
        Datos para el envío a domicilio
      </div>
      <br>
      <validated-outlined-text-input
          @change="sendAddressUpdated"
          label="Dirección de envío"
          :model="localShipmentData.address"
          required
          id="searchPlace"
          placeholder=""
      />
      <div id="map" class="map" />
      <br>
      <validated-outlined-text-input
          @change="sendAddressCommentsUpdated"
          label="Piso/Depto/Timbre"
          :model="localShipmentData.addressComments"
          required
      />
      <validated-outlined-text-input
          @change="addressReceiverUpdated"
          label="Quién lo recibe"
          :model="localShipmentData.addressReceiver"
          required
      />
      <phone-number-input
          @change="receiverPhoneUpdated"
          label="Teléfono de quien lo recibe"
          :model="localShipmentData.addressPhone"
      />
      <div>
        Seleccionar una fecha y hora para el retiro
      </div>
      <v-dialog
          ref="dialog"
          v-model="menu1"
          persistent
          width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <validation-provider
              v-slot="{ errors }"
              :rules="{required: true,}"
          >
            <v-text-field
                v-model="formattedDate"
                label="Hacé click para seleccionar fecha y hora"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :error-messages="errors"
            ></v-text-field>
          </validation-provider>
        </template>
        <v-date-picker
            v-model="date"
            :show-current="true"
            @input="onChangeDate(date)"
            :allowed-dates="allowedDates"
            scrollable
        ></v-date-picker>
      </v-dialog>
    </v-card>
  </v-scale-transition>
</template>

<script>

import { Loader } from '@googlemaps/js-api-loader'
import Swal from "sweetalert2";
import {ValidationProvider} from "vee-validate";
import ShipmentData from "../../../model/ShipmentData";
import PhoneNumberInput from "../../Form/PhoneNumberInput";
import ValidatedOutlinedTextInput from "../../Form/ValidatedOutlinedTextInput";

export default {
  name: "SendForm",

  components: {
    PhoneNumberInput,
    ValidatedOutlinedTextInput,
    ValidationProvider,
  },

  props: {
    shipmentData: {
      required: true,
      validator: function (value) {
        return value instanceof ShipmentData;
      },
    }
  },

  data: () => ({
    localShipmentData: null,

    map: null,
    loader: null,
    marker: null,
    searchBox: null,

    date: null,
    formattedDate: null,
    now: null,
    allowed: [],
    menu1: false,
  }),

  beforeMount() {
    this.now = new Date(Date.now());
    this.allowed = this.calendar.map((x) => [
      x.date.toISOString().substring(0, 10),
      x.startTime,
      x.endTime
    ]);

    this.localShipmentData = this.shipmentData
  },

  mounted() {
    this.loader = new Loader({
      apiKey: "AIzaSyAAJl8Nt-ZsPfGWRWvog-RzQmra5jsd3P8",
      version: "weekly",
      libraries: ["places"]
    });

    this.address_price = 1;

    this.createMap();

  },

  methods: {
    onChangeDate(date) {
      this.formattedDate = this.formatDate(date);
      this.$emit('dateTimeUpdated', `${this.formattedDate} 17:00:00`)
      this.menu1 = false;
    },

    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

    allowedDates(v) {
      return this.arrayHasElement(this.allowed, v.substring(0, 10));
    },

    arrayHasElement(array, element) {
      return array.filter(e => e[0] === element).length > 0;
    },

    receiverPhoneUpdated(payload) {
      this.localShipmentData.addressPhone = payload.formattedNumber.replace('+','');
      this.$emit('shipmentDataUpdated', this.localShipmentData)
    },

    sendAddressUpdated(payload) {
      this.localShipmentData.address = payload;
      this.$emit('shipmentDataUpdated', this.localShipmentData);
    },

    addressReceiverUpdated(payload) {
      this.localShipmentData.addressReceiver = payload;
      this.$emit('shipmentDataUpdated', this.localShipmentData);
    },

    sendAddressCommentsUpdated(payload) {
      this.localShipmentData.addressComments = payload;
      this.$emit('shipmentDataUpdated', this.localShipmentData);
    },

    sendAddressPriceUpdated(payload) {
      this.localShipmentData.addressPrice = payload;
      this.$emit('shipmentDataUpdated', this.localShipmentData);
    },

    createMap() {
        this.loader.load().then((google) => {

          let coordinates = {lat: -34.6083, lng: -58.3712}

          let map = this.initializeMap(google, coordinates);

          this.searchBox = new google.maps.places.SearchBox(document.getElementById("searchPlace"));

          map.addListener("bounds_changed", () => {
            this.searchBox.setBounds(map.getBounds());
          });

          this.searchBox.addListener("places_changed", () => {
            try {
              const bounds = new google.maps.LatLngBounds();
              const place = this.searchBox.getPlaces()[0];
              const location = this.searchBox.getPlaces()[0].geometry.location;
              const municipality = place.address_components.filter(address => address.types.includes("administrative_area_level_2"))[0].short_name;
              const price = this.municipalities.find(order => order.name === municipality).price;

              bounds.extend(location);
              map.fitBounds(bounds);

              this.localShipmentData.addressLat = location.lat();
              this.localShipmentData.addressLng = location.lng();
              this.sendAddressUpdated(place.formatted_address);

              this.marker = this.createMarker(google, {lat: this.localShipmentData.addressLat, lng: this.localShipmentData.addressLng}, map)
              this.sendAddressPriceUpdated(price);
              this.notifyPrice(municipality, price);
            } catch (e) {
              console.log(e);
              this.notifyNoMunicipality();
            }

          });

          if(this.localShipmentData.addressLat != null && this.localShipmentData.addressLng != null && this.localShipmentData.addressLat !== '' && this.localShipmentData.addressLng !== '') {
            this.marker = this.createMarker(google, {lat: this.localShipmentData.addressLat, lng: this.localShipmentData.addressLng}, map)
            this.simulatePlacesChanged(map, google)
          }

        }).catch(e => {
          console.log(e);
        });
    },

    simulatePlacesChanged(map, google) {
      try {
        const bounds = new google.maps.LatLngBounds();
        const location = new google.maps.LatLng(this.localShipmentData.addressLat, this.localShipmentData.addressLng);

        bounds.extend(location);
        map.fitBounds(bounds);

        const geocoder = new google.maps.Geocoder();
        const latLng = new google.maps.LatLng(this.localShipmentData.addressLat, this.localShipmentData.addressLng);
        geocoder.geocode({ location: latLng }, (results, status) => {
          if (status === 'OK' && results[0]) {
            const placeInfo = results[0];
            const place = results[0];
            const municipality = place.address_components.filter(address => address.types.includes("administrative_area_level_2"))[0].short_name;
            const price = this.municipalities.find(order => order.name === municipality).price;
            this.sendAddressPriceUpdated(price);
            this.notifyPrice(municipality, price);
          } else {
            console.error('Geocoding failed:', status);
          }
        });

      } catch (e) {
        console.log(e);
      }
    },

    initializeMap(google, coordinates) {
      return new google.maps.Map(document.getElementById("map"), {
        center: coordinates,
        zoom: 11,
        mapTypeControl: false,
        streetViewControl: false,
        minZoom: 11,
        maxZoom: 16,
      });
    },

    createMarker(google, coordinates, map) {
      if(this.marker != null) this.marker.setMap(null);

      return new google.maps.Marker({
        position: coordinates,
        map,
      });
    },

    notifyPrice(municipality, price) {
      Swal.fire({
        title: 'Estimado cliente',
        html: 'El envío a ' + municipality + ' tiene un cargo de ' + price + ' ARS. En caso de que el paquete contenga ARG, el costo será retenido del mismo. En caso contrario, coordinar por Whatsapp.',
        icon: 'warning',
        showConfirmButton: false,
        timer: 8000,
        scrollbarPadding: false,
      });
    },

    notifyNoMunicipality() {
      Swal.fire({
        title: 'Estimado cliente',
        html: 'Lamentablemente no realizamos envíos a la zona seleccionada. Coordinar el envío por Whatsapp',
        icon: 'error',
        showConfirmButton: false,
        timer: 8000,
        scrollbarPadding: false,
      });
    }
  },

  computed: {
    municipalities () {return this.$store.state.municipalities},
    calendar () {
      if (!this.immediateAccreditation) {
        return this.$store.state.dates.slice(2)
      }
      return this.$store.state.dates
    },
    immediateAccreditation () {return this.$store.state.selected_rate.systemFrom.immediateAccreditation},
  }
}
</script>

<style>
html,
body,
#map {
  height: 250px;
  width: 100%;
  margin: 0;
  padding: 0;
}
</style>