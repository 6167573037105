var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('validation-provider',{attrs:{"name":"Email","rules":{
              required: _vm.required,
              email: true,
              gmailValidation: 'gmail',
              hotmailValidation: 'hotmail',
              liveValidation: 'live',
              outlookValidation: 'outlook',
              yahooValidation: 'yahoo',
            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.field_label,"append-icon":_vm.field_icon,"solo":"","filled":"","hint":_vm.hint},on:{"change":_vm.onUpdateValue},model:{value:(_vm.field_model),callback:function ($$v) {_vm.field_model=$$v},expression:"field_model"}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }