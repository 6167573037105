export default class UserData {
    constructor(data) {
        this.clientName = data.name || null;
        this.areaCode = data.area_code || null;
        this.phone = data.phone || null;
        this.clientMail = data.email || null;
    }

    static Blank() {
        return new UserData({
            client_name: '',
            area_code: '',
            phone: '',
            client_mail: '',
        });
    }
}