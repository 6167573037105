<template>
  <v-card height='100%' class="py-10 px-4 rounded-lg" outlined hover v-if="!mobile" >
    <slot></slot>
  </v-card>
  <v-card v-else height='100%' class="py-10 px-0 rounded-lg" outlined elevation="6" >
    <slot></slot>
  </v-card>
</template>

<script>
export default {
  name: "WebCard",

  data: () => ({
    mobile: false,
  }),

  mounted() {
    this.mobile = this.isMobile;
  },

  watch: {
    "$store.state.window.mobile"(nv) {
      this.mobile = nv;
    },
  },

  computed: {
    isMobile () {
      return this.$store.state.window.mobile;
    },
  },
}
</script>