<template>
  <div v-if="!mobile" class="my-4">
    <v-row class="mb-5">
      <div style="position: relative; left: -50px; height: 40px; width: 40px; border-radius: 50%;" class="background-color-primary-shade6"/>
      <h1 style="position: relative; left: -40px;align-self: center;" class="color-primary">{{ title }}</h1>
    </v-row>
    <v-row justify="center">
      <v-col class="col-lg-4 col-sm-12" v-for="(item, index) in items">
        <service
            :svg-asset="item.svgAsset"
            :text="item.text"
            :key="index"
        />
      </v-col>
    </v-row>
  </div>
  <div v-else>
    <v-row justify="center" class="mb-5">
      <h3 class="color-primary centered-h3">{{ title }}</h3>
    </v-row>
    <carousel :height="250">
      <v-carousel-item v-for="(item, index) in items" :key="index">
        <v-row justify="center">
          <v-col class="col-8">
            <service
                :svg-asset="item.svgAsset"
                :text="item.text"
            />
          </v-col>
        </v-row>
      </v-carousel-item>
    </carousel>
  </div>
</template>

<script>
import '../../main.css';
import Service from './Service';
import Carousel from "../Common/Carousel";

export default {
  name: 'ServicesSection',

  components: {
    Service,
    Carousel,
  },

  data: () => ({
    title: '¿QUÉ SERVICIOS OFRECEMOS?',
    items: [
      {
        text: 'TRANSFERENCIAS BANCARIAS',
        svgAsset: require('../../assets/icons/services/bank.svg'),
      },
      {
        text: 'PESOS/USD EN EFECTIVO',
        svgAsset: require('../../assets/icons/services/cash.svg'),
      },
      {
        text: 'RECIBIR/ENVIAR DINERO A CUENTAS EXTRANJERAS',
        svgAsset: require('../../assets/icons/services/transfer.svg'),
      },
      {
        text: 'INTERCAMBIO DE CRYPTOMONEDAS',
        svgAsset: require('../../assets/icons/services/crypto.svg'),
      },
      {
        text: 'ENVÍOS A DOMICILIO',
        svgAsset: require('../../assets/icons/services/delivery.svg'),
      },
      {
        text: 'ATENCIÓN PERSONALIZADA',
        svgAsset: require('../../assets/icons/services/support.svg'),
      },
    ],
    mobile: false,
  }),

  mounted() {
    this.mobile = this.isMobile;
  },

  watch: {
    "$store.state.window.mobile"(nv) {
      this.mobile = nv;
    },
  },

  computed: {
    isMobile () {
      return this.$store.state.window.mobile;
    },
  },
}
</script>
<style>
.centered-h3 {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.2;
}
</style>