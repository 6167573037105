<template>
  <dynamic-container class="pb-10" :key="redrawKey">
    <v-row justify="center" class="mx-0 mt-5 fill-height" v-if="!mobile" >
      <dynamic-column aspect_ratio="full" class="mx-5">
        <exchange-matrix
            :rates="rateList"
            :systems="systemList"
            title="Payoneer"
            first-column-header=""
            :columns="[1, 2, 3, 4, 5, 6, 7, 8]"
            :rows="[1, 5]"
            headerType="name"
        />
      </dynamic-column>
    </v-row>
    <v-row justify="center" class="mx-0 mt-5 fill-height" v-else>
      <rates-card-section :system-list="systemList" :rates="rateList" />
    </v-row>
    <v-row justify="center" class="mx-0 mt-5 fill-height" v-if="!mobile" >
      <dynamic-column aspect_ratio="full" class="mx-5">
        <details-table
            :rates="rateList"
            :systems="systemList"
            :rows="[4, 18, 19]"
            title="Bajar cable - Liquidación a 48hs contra acreditación"
        />
      </dynamic-column>
    </v-row>
    <v-row justify="center" class="mx-0 mt-5 fill-height" v-if="!mobile" >
      <dynamic-column aspect_ratio="full" class="mx-5">
        <exchange-matrix
            :rates="rateList"
            :systems="systemList"
            title="Billete"
            first-column-header=""
            :columns="[7, 12, 13, 6]"
            :rows="[7, 12, 13, 6]"
            headerType="abbreviation"
        />
      </dynamic-column>
    </v-row>
  </dynamic-container>
</template>

<script>
import '../main.css';
import RatesMatrix from '../components/Rates/RatesMatrix';
import RatesTable from '../components/Rates/RatesTable';
import DynamicColumn from '../components/Common/DynamicColumn';
import DynamicContainer from "../components/Common/DynamicContainer";
import ExchangeMatrix from "../components/prices/ExchangeMatrix";
import DetailsTable from "../components/prices/DetailsTable";
import System from "../model/System";
import Rate from "../model/Rate";
import RatesCardSection from "../components/prices/RatesCardSection";

export default {
  name: 'Rates',

  components: {
    RatesMatrix,
    DynamicColumn,
    RatesTable,
    DynamicContainer,
    ExchangeMatrix,
    DetailsTable,
    RatesCardSection,
  },

  data: () => ({
    ratesRows: [7, 12, 13, 6],
    ratesColumns: [7, 12, 13, 6],
    redrawKey: 0,
    mobile: false,

    rateList: [],
    systemList: [],
  }),

  beforeMount() {
    this.handleRates(this.getRates)
  },

  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },

  methods: {
    handleResize() {
      this.mobile = window.innerWidth <= 800;
    },

    handleRates() {
      this.systemList = this.getSystems()
      this.rateList = this.getRates()

      this.redrawKey++
    },

    getRates () {
      return this.$store.state.rates;
    },

    getSystems () {
      return this.$store.state.systems;
    },

  },

  computed: {
    loading () {
      return this.$store.state.rates_loading;
    },
  },

  watch: {
    "$store.state.rates"() {
      this.handleRates();
      this.redrawKey++
    },
  },
};
</script>