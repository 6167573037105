<template>
  <div>
    <validation-provider
        v-slot="{ errors }"
        :name="label"
        :rules="validation"
    >
        <v-text-field
            filled
            reverse
            outlined
            hide-details
            :error-messages="errors"
            class="pa-0 velc-amount-input"
            :label="label"
            :value="model"
            @input="amountUpdated"
            type="number"
            v-on:blur="handleBlur"
        />
        <error-bubble :text="errors[0]"/>
    </validation-provider>
  </div>
</template>

<script>
import {extend, setInteractionMode, ValidationProvider} from 'vee-validate'
import {numeric, required} from 'vee-validate/dist/rules'
import ErrorBubble from "../../components/Common/ErrorBubble";

setInteractionMode('eager')

extend('positive', {
  validate: value => {
    return value > 0;
  },
  message: 'El monto a enviar debe ser positivo'
});

extend('required', {
  ...required,
  message: 'Este campo es requerido',
})

extend('amountBetween', {
  validate(value, {min, max}) {
    return Number(value) >= Number(min) && Number(value) <= Number(max);
  },
  params: ['min', 'max', 'currency'],
  message: 'Este monto debe ser entre {min} y {max} {currency}'
});

export default {
  name: 'AmountInput',

  props: {
    model: {
      type: Number,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    min: {
      type: Number,
      required: true,
    },

    max: {
      type: Number,
      required: true,
    },

    noSpecialChars: Boolean,
    numeric: Boolean,
    required: Boolean,
  },

  components: {
    ValidationProvider,
    ErrorBubble,
  },

  data: () => ({
    field_label: "",
    field_model: "",
    field_rules: "",
    validation: 'req|positive',
  }),

  mounted() {
    this.field_model = this.amount;
    if(this.required) {
      this.field_rules = 'required';
    }
    if(this.noSpecialChars) {
      this.field_rules += '|noSpecialChars';
    }
    if(this.numeric) {
      this.field_rules += '|numeric';
    }
  },

  beforeMount() {
    this.addMinMaxValidations()
  },

  methods: {
    validate() {
      //Comparar con minimo y maximo
      //validar redondeo y mostrar opciones
    },

    amountUpdated: function(payload) {
      this.$emit('amountUpdated', Number.parseInt(payload))
    },

    handleBlur: function() {
      this.$emit('amountBlurred', '')
    },

    addMinMaxValidations() {
      this.validation = 'req|positive|amountBetween:' + this.min + ',' + this.max + ',' + this.label
    }
  },
}
</script>
<style scoped>
.velc-amount-input>.v-input__control .v-input__slot {
  min-height: 50px !important;
  display: flex !important;
  align-items: center !important;
  font-size: 35px !important;
}

.velc-amount-input {
  height: 64px !important;
}

.velc-amount-input>.v-input__input {
  height: 64px !important;
}

.velc-amount-input.v-text-field--filled>.v-input__control>.v-input__slot {
  background: #e6f4eb !important;
}

.velc-amount-input.v-text-field--filled>.v-input__control>.v-input__slot:hover {
  background: #e6f4eb !important;
}

.velc-amount-input .v-label {
  font-size: 0.7em;
  color: var(--color-primary);
}
</style>