<template>
  <v-main>
    <v-row align="center" justify="center">
      <v-col align="center" cols="12" lg="4">
        <v-img
          alt="Mail sent"
          class="shrink mr-2"
          contain
          :src="require('../../src/assets/mail_sent.svg')"
          transition="scale-transition"
          width="400"
        />
        <div class="text-header-1 my-10" >
          ¡Revisá tu casilla de correo!
        </div>
        <div class="text-subheader my-10" >
          Vamos a enviarte las instrucciones para seguir con el proceso mientras uno de nuestros analistas da curso a tu pedido.
        </div>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import '../main.css';

export default {
  name: 'Confirmation',

  data: () => ({
    //
  }),
};
</script>