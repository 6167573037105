<template>
  <v-img
      contain
      :src="svgAsset"
      transition="scale-transition"
      :height="height"
  />
</template>

<script>
export default {
  name: 'Platform',
  props: {
    svgAsset: {
      type: String,
      required: true
    },
    height: {
      type: Number,
      required: true,
    }
  },
};
</script>