<template>
  <v-col
      xs="12"
      sm="10"
      md="8"
      :lg=col_lg
      :xl=col_xl
      v-bind:class="{ 'px-0': mobile, 'mx-0': mobile }"
  >
    <slot></slot>
  </v-col>
</template>

<script>
export default {
  name: "WebColumn",

  props: {
    aspect_ratio: String,
  },

  data: function() {
    return {
      mobile: false,
      full: this.aspect_ratio === "full",
      col_lg: this.aspect_ratio === "full" ? 12 : 6,
      col_xl: this.aspect_ratio === "full" ? 8 : 4,
    }
  },

  beforeMount() {
    this.mobile = this.isMobile;
  },

  watch: {
    "$store.state.window.mobile"(nv) {
      this.mobile = nv;
    },
  },

  computed: {
    isMobile () {
      return this.$store.state.window.mobile;
    },
  },
}
</script>