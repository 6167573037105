import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Confirmation from '../views/Confirmation.vue'
import Terms from '../views/Terms.vue'
import Policies from '../views/Policies.vue'
import FAQs from '../views/FAQs.vue'
import Rates from '../views/Rates.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: (route) => ({ token: route.query.token, referral_code: route.query.referral_code }),
  },
  {
    path: '/confirmation',
    name: 'Confirmation',
    component: Confirmation
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/policies',
    name: 'Policies',
    component: Policies
  },
  {
    path: '/faqs',
    name: 'FAQs',
    component: FAQs
  },
  {
    path: '/rates',
    name: 'Rates',
    component: Rates
  },
]

const router = new VueRouter({
  mode: 'history',
  routes: routes
})

export default router
