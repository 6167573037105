<template>
  <v-data-table
      :headers="headers"
      :items="items"
      hide-default-footer
      class="elevation-6 mx-auto py-10 px-4 rounded-lg"
  >
    <template v-slot:top>
      <v-toolbar
          flat
      >
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
    </template>
  </v-data-table>
</template>

<script>

import System from "../../model/System";
import Rate from "../../model/Rate";

export default {
  name: 'DetailsTable',

  props: {
    rates: {
      type: Array,
      required: true,
      validator: function (value) {
        return value.every(item => item instanceof Rate);
      },
    },

    systems: {
      type: Array,
      required: true,
      validator: function (value) {
        return value.every(item => item instanceof System);
      },
    },

    title: {
      type: String,
    },

    rows: {
      type: Array,
      default: [],
      required: true,
    },
  },

  components: {},

  data: () => ({
    headers: [
      { text: 'Mínimo', value: 'system.minAmountReceive' },
      { text: 'Máximo', value: 'system.maxAmountReceive' },
      { text: 'Fijo', value: 'system.tax' },
      { text: 'Variable', value: 'price' },
      { text: 'Ejemplo', value: 'example' },
    ],
    items: [],
  }),

  beforeMount() {
    this.populateRates();
  },

  methods: {
    populateRates() {
      this.items = []

      const filteredSystemsFrom = this.systems.filter(system => this.rows.includes(system.id))

      filteredSystemsFrom.map((row) => {
        const rate = this.rates.find(rate => rate.systemTo.id === 7 && rate.systemFrom.id === row.id)
        const item = {
          system: row,
          price: rate.price,
          example: rate.buildExampleEquation(),
        }
        this.items.push(item)
      })
    },
  },
}
</script>