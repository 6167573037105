<template>
  <v-card height='100%' class="py-10 px-4 rounded-lg" outlined hover v-if="!mobile" >
    <slot></slot>
  </v-card>
  <v-card v-else height='100%' class="py-10 px-0 rounded-lg" outlined elevation="6" >
    <slot></slot>
  </v-card>
</template>

<script>
export default {
  name: "ResponsiveCard",

  data: () => ({
    mobile: false,
  }),

  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },

  methods: {
    handleResize() {
      this.mobile = window.innerWidth <= 1264;
    },
  },
}
</script>