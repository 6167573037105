<template>
  <v-app-bar
      app
      color="primary"
      dark
      :height="appBarHeight"
  >
    <v-row v-if="!this.isMobile" justify="center" class="align-center">
      <v-col
          class="pl-0 pr-0"
          xs="6"
          sm="5"
          md="4"
          lg="6"
          xl="4"
      >
        <div class="d-flex align-center">
          <router-link to="/">
            <v-img
                alt="Velc Logo"
                contain
                :src="require('../../../src/assets/logo.svg')"
                transition="scale-transition"
                width="230"
                class="mx-0"
            />
          </router-link>
          <router-link to="/Rates">
            <v-list-item>
              <v-list-item-title>COTIZACIONES</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link to="/FAQs">
            <v-list-item>
              <v-list-item-title>PREGUNTAS FRECUENTES</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link to="/Terms">
            <v-list-item>
              <v-list-item-title>T&C</v-list-item-title>
            </v-list-item>
          </router-link>
        </div>
      </v-col>
      <v-col
          class="text-right pl-0 pr-0"
          xs="6"
          sm="5"
          md="4"
          lg="6"
          xl="4"
      >
        <v-btn
            href="https://app.velcfinanzas.com"
            target="_blank"
            text
            class="mx-0 px-0"
        >
          <span class="mr-0 ml-2">Iniciar Sesión</span>
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </v-col>
    </v-row>



    <v-row v-else justify="center">
      <v-col
          xs="12"
          sm="10"
          md="8"
          lg="12"
          xl="8"
          class="px-0 mx-0"
      >
        <div class="d-flex align-center">
          <v-col>
            <router-link to="/">
            <v-img
                v-if="this.window.width < 1264"
                alt="Velc Logo"
                contain
                :src="require('../../../src/assets/logo_180_60.png')"
                transition="scale-transition"
                width="100"
                class="mx-0 px-0"
            />
            </router-link>
          </v-col>
          <v-col class="text-right">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn text v-on="on">Menu</v-btn>
              </template>
              <v-list>
                <a href="https://app.velcfinanzas.com" target="_blank" rel="noopener noreferrer">
                  <v-list-item>
                    <v-list-item-title>INICIAR SESIÓN</v-list-item-title>
                  </v-list-item>
                </a>
                <router-link to="/Rates" style="text-decoration: none;">
                  <v-list-item>
                    <v-list-item-title>COTIZACIONES</v-list-item-title>
                  </v-list-item>
                </router-link>
                <router-link to="/FAQs" style="text-decoration: none;">
                  <v-list-item>
                    <v-list-item-title>PREGUNTAS FRECUENTES</v-list-item-title>
                  </v-list-item>
                </router-link>
                <router-link to="/Terms" style="text-decoration: none;">
                  <v-list-item>
                    <v-list-item-title>TÉRMINOS Y CONDICIONES</v-list-item-title>
                  </v-list-item>
                </router-link>
              </v-list>
            </v-menu>
          </v-col>
        </div>
      </v-col>
    </v-row>

  </v-app-bar>
</template>

<script>
export default {
  name: 'DynamicAppBar',

  data: () => ({
    window: {
      width: 0,
      height: 0,
    }
  }),

  mounted() {},

  watch: {
    "$store.state.window.mobile"(nv) {
      this.mobile = nv;
    },
  },

  computed: {
    isMobile () {
      return this.$store.state.window.mobile;
    },
    appBarHeight () {
      return this.$store.state.window.mobile ? 50 : 100;
    }
  },
}
</script>