<template>
  <validation-observer
      ref="observer"
      v-slot="{ invalid }"
  >
    <form @submit.prevent="submit">
      <v-col class="px-1" cols="12">
        <div id="basic-data">
          <validated-outlined-text-input @change="setName" label="Nombre y Apellido" required />
          <phone-input-number @change="setPhone" label="Teléfono" />
          <validated-mail-input @change="setMail" label="Email" hint="Vamos a contactarte por este medio" required important />
          <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Método de envío"
          >
            <v-select
                :items="shipmentTypes"
                v-model="shipmentType"
                :error-messages="phoneErrors"
                label="Método de envío"
                item-text="name"
                item-value="id"
                class="mx-auto"
                dense
                outlined
                required
            ></v-select>
          </validation-provider>
        </div>

        <div v-if="shipmentType === 1">
          <send-form/>
        </div>

        <v-scale-transition>
          <v-card class="pa-5 rounded-lg" outlined v-if="shipmentType === 2">
            <div>
              Datos para la transferencia
            </div>
            <br>
            <validated-outlined-text-input @change="setTransferBank" label="Banco" :model="transfer_bank" required />
            <validated-outlined-text-input @change="setTransferHolder" label="Titular de la cuenta" :model="transfer_holder" required />
            <validated-outlined-text-input @change="setTransferAlias" label="Alias" :model="transfer_alias" required />
            <validated-outlined-text-input @change="setTransferCuit" label="CUIT" :model="transfer_cuit" noSpecialChars numeric required />
            <validated-outlined-text-input @change="setTransferCbu" label="CBU/CVU" :model="transfer_cbu" noSpecialChars numeric required />
            <validated-outlined-text-input @change="setTransferAccountNumber" label="Nro. de cuenta" :model="transfer_account_number" hint="No es necesiario si tenés Ualá o Mercado Pago" noSpecialChars numeric />
          </v-card>
        </v-scale-transition>

        <v-scale-transition>
          <v-card class="pa-5 rounded-lg" outlined v-if="shipmentType === 3">
            <div>
              Datos para la transferencia
            </div>
            <br>
            <validated-outlined-text-input @change="setAccountEmail" label="Cuenta Correo Electrónico" :model="account_email" required />
          </v-card>
        </v-scale-transition>

        <v-scale-transition>
          <v-card class="pa-5 rounded-lg" outlined v-if="shipmentType === 4">
            <div>
              Datos para la transferencia
            </div>
            <br>
            <validated-outlined-text-input @change="setWalletAddress" label="Dirección Tether USDT" :model="wallet_adress" hint="Por ejemplo TRC20: TMx5KvqyTbnFBM70vyHo9awHCoqpQgua99" required />
          </v-card>
        </v-scale-transition>

        <div v-if="shipmentType === 5">
          <withdraw-form/>
        </div>

        <div>
          <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="checkbox"
          >


            <v-checkbox
                v-model="checkbox"
                :error-messages="errors"
                value="1"
                type="checkbox"
                required
                dense
                outlined
            >
              <template v-slot:label>
                <div>
                  Acepta nuestros
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <router-link :to="'/terms'">términos y condiciones</router-link>
                    </template>
                    .
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>


          </validation-provider>
        </div>
        <v-row
            class="mt-5 justify-space-between"
        >
          <v-col
              align="end"
              justify="end">
            <v-btn
                class="mr-0"
                type="submit"
                :disabled="invalid || notValidAmounts"
                color="primary"
                @click="submitForm"
            >
              Iniciar Pedido
              <v-icon
                  dark
                  right
              >
                mdi-checkbox-marked-circle
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </form>
  </validation-observer>
</template>

<script>

import {required} from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import {default as axios} from "axios";
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import Vue from "vue";
import '../../main.css';
import router from "../../router";
import Swal from "sweetalert2";
import PhoneInputNumber from "./PhoneNumberInput";
import ValidatedOutlinedTextInput from "./ValidatedOutlinedTextInput";
import ValidatedMailInput from "./ValidatedMailInput";
import SendForm from "./SendForm";
import WithdrawForm from "./WithdrawForm";

setInteractionMode('eager')

extend('required', {
  ...required,
  message: 'Este campo es requerido',
})

export default {
  name: "Form",

  props: {},

  components: {
    ValidatedOutlinedTextInput,
    PhoneInputNumber,
    ValidationProvider,
    ValidationObserver,
    ValidatedMailInput,
    SendForm,
    WithdrawForm,
  },

  data: () => ({
    name: null,
    phone: null,
    email: null,

    transfer_bank: null,
    transfer_holder: null,
    transfer_alias: null,
    transfer_cuit: null,
    transfer_cbu: null,
    transfer_account_number: null,

    account_email: null,

    wallet_adress: null,

    checkbox: null,

    shipmentType: null,
    shipmentTypes: [],

    order: null,

    notValidAmounts: true,

    phoneErrors: null,
  }),

  methods: {
    submit () {this.$refs.observer.validate();},
    setPhone(payload) {this.phone = payload.formattedNumber.replace('+','') ?? null;},
    setName(payload) {this.name = payload ?? null;},
    setMail(payload) {this.email = payload ?? null;},
    setTransferBank(payload) {this.transfer_bank = payload ?? null;},
    setTransferHolder(payload) {this.transfer_holder = payload ?? null;},
    setTransferAlias(payload) {this.transfer_alias = payload ?? null;},
    setTransferCuit(payload) {this.transfer_cuit = payload ?? null;},
    setTransferCbu(payload) {this.transfer_cbu = payload ?? null;},
    setTransferAccountNumber(payload) {this.transfer_account_number = payload ?? null;},
    setAccountEmail(payload) {this.account_email = payload ?? null;},
    setWalletAddress(payload) {this.wallet_adress = payload ?? null;},

    submitForm () {
      if(this.phone === null) {
        return;
      }

      if((this.address_phone === null) && (this.shipmentType === 1)) {
        return;
      }

      if(((this.address_lat === null) || (this.address_lng === null)) && (this.shipmentType === 1)) {
        Swal.fire({
          title: '¡Oops!',
          html: 'Hay un problema con la dirección del envío. Por favor, asegurate de hacer click en una dirección entre las opciones.',
          icon: 'error',
          showConfirmButton: false,
          timer: 5000,
          scrollbarPadding: false,
        });
        return;
      }

      this.order = {
        ...(this.rate_id != null && {"rate_id": this.rate_id}),
        ...(this.rate_price != null && {"rate_price": this.rate_price}),
        ...(this.send_amount != null && {"send_amount": Number(this.send_amount).toFixed(2)}),
        ...(this.receive_amount != null && {"receive_amount": Number(this.receive_amount).toFixed(2)}),
        ...(this.shipmentType != null && {"shipment_id": this.shipmentType}),
        ...(this.name != null && {"client_name": this.name}),
        ...(this.phone != null && {"whatsapp": this.phone}),
        ...(this.email != null && {"client_mail": this.email}),
        ...(this.transfer_bank != null && {"transfer_bank": this.transfer_bank}),
        ...(this.transfer_holder != null && {"transfer_holder": this.transfer_holder}),
        ...(this.transfer_alias != null && {"transfer_alias": this.transfer_alias}),
        ...(this.transfer_cuit != null && {"transfer_cuit": this.transfer_cuit}),
        ...(this.transfer_cbu != null && {"transfer_cbu": this.transfer_cbu}),
        ...(this.transfer_account_number != null && {"transfer_account_number": this.transfer_account_number}),
        ...(this.wallet_adress != null && {"wallet_adress": this.wallet_adress}),
        ...(this.account_email != null && {"account_email": this.account_email}),
        ...(this.address != null && {"address": this.address + ' - ' + this.address_comments}),
        ...(this.address_receiver != null && {"address_receiver": this.address_receiver}),
        ...(this.address_phone != null && {"address_telephone": this.address_phone}),
        ...(this.address_lat != null && {"address_lat": this.address_lat}),
        ...(this.address_lng != null && {"address_lng": this.address_lng}),
        ...(this.address_price != null && {"address_price": this.address_price}),
        ...(this.withdrawal_date != null && {"withdrawal_datetime": this.withdrawal_date}),
      };

      Swal.fire({
        title: 'Procesando...',
        html: 'Estamos dando de alta tu pedido.',
        allowOutsideClick: false,
        scrollbarPadding: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      axios
          .post(process.env.VUE_APP_API_URL + '/api/orders', this.order)
          .then(response => {
            if(response.status === 201) {
              Swal.close();
              router.push("Confirmation");
            } else {
              Swal.fire({
                title: '¡Oops!',
                html: 'Hubo un error al procesar tu pedido. Por favor, intentá de nuevo.',
                icon: 'error',
                showConfirmButton: false,
                timer: 5000,
                scrollbarPadding: false,
              });
            }
          })
          .catch(e => {
            Swal.fire({
              title: '¡Oops!',
              html: 'Hubo un error al procesar tu pedido. Por favor, intentá de nuevo.',
              icon: 'error',
              showConfirmButton: false,
              timer: 5000,
              scrollbarPadding: false,
            });
          });
    },
  },

  watch: {
    "$store.state.shipmentTypes"(nv) {
      this.shipmentTypes = nv;
      if(this.shipmentTypes.length === 1) {
        this.shipmentType = nv[0].id;
      } else {
        this.shipmentType = null;
      }
    },

    "$store.state.not_valid_amounts"(nv) {
      this.notValidAmounts = nv;
    },
  },

  computed: {
    rate_id () {return this.$store.state.rate_id;},
    send_amount () {return this.$store.state.send_amount;},
    rate_price () {return this.$store.state.rate_price;},
    receive_amount () {return this.$store.state.receive_amount;},
    address () {return this.$store.state.address;},
    address_receiver () {return this.$store.state.address_receiver;},
    address_phone () {return this.$store.state.address_phone;},
    address_lat () {return this.$store.state.address_lat;},
    address_lng () {return this.$store.state.address_lng;},
    address_price () {return this.$store.state.address_price;},
    address_comments () {return this.$store.state.address_comments;},
    withdrawal_date () {return this.$store.state.withdrawal_date;},
  },
}
</script>

