<template>
  <div>
    <div v-if="shipmentType.id === 1">
      <send-form
          :shipment-data="shipmentData"
          @shipmentDataUpdated="shipmentDataUpdated"
          @dateTimeUpdated="dateTimeUpdated"
      />
    </div>
    <div v-if="shipmentType.id === 2">
      <bank-transfer-form
          :accounts-data="bankAccountsData()"
      />
    </div>
    <div v-if="shipmentType.id === 3">
      <email-transfer-form
          :accounts-data="emailAccountsData()"
      />
    </div>
    <div v-if="shipmentType.id === 4">
      <wallet-transfer-form
          :accounts-data="walletAccountsData()"
      />
    </div>
    <div v-if="shipmentType.id === 5">
      <withdraw-form
          @dateTimeUpdated="dateTimeUpdated"
      />
    </div>
  </div>
</template>

<script>

import SendForm from "./SendForm";
import WithdrawForm from "./WithdrawForm";
import ValidatedOutlinedTextInput from "../../Form/ValidatedOutlinedTextInput";
import AccountData from "../../../model/AccountData";
import BankTransferForm from "../account/BankTransferForm";
import EmailTransferForm from "../account/EmailTransferForm";
import WalletTransferForm from "../account/WalletTransferForm";

export default {
  name: 'ShipmentForm',

  props: {
    shipmentType: {
      required: true,
    },

    shipmentData: {
      required: true,
    },

    accountsData: {
      type: Array,
      required: true,
      validator: function (value) {
        return value.every(item => item instanceof AccountData);
      },
    },
  },

  components: {
    SendForm,
    WithdrawForm,
    ValidatedOutlinedTextInput,
    BankTransferForm,
    EmailTransferForm,
    WalletTransferForm,
  },

  data: () => ({}),

  beforeMount() {},

  mounted() {},

  methods: {
    dateTimeUpdated(payload) {
      this.$emit('dateTimeUpdated', payload)
    },

    shipmentDataUpdated(payload) {
      this.$emit('shipmentDataUpdated', payload);
    },

    bankAccountsData() {
      return this.accountsData.filter(accountData => accountData.type === 1)
    },

    emailAccountsData() {
      return this.accountsData.filter(accountData => accountData.type === 3)
    },

    walletAccountsData() {
      return this.accountsData.filter(accountData => accountData.type === 2)
    },
  }
}
</script>
