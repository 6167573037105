<template>
  <button @click="submitForm">Enviar</button>
</template>

<script>
import axios from 'axios';
import { load } from 'recaptcha-v3'

export default {
  name: 'CaptchaButton',

  data: () => ({
    recaptcha: null,
    contactName: null,
    contactMail: null,
    contactOrder: null,
    contactMessage: null,
    contactAppl: null,
  }),

  mounted() {
    load(process.env.VUE_APP_GOOGLE_CAPTCHA).then((recaptcha) => this.recaptcha = recaptcha)
  },

  methods: {
    submitForm() {
      this.recaptcha.execute('contact_form').then((token) => {
        //console.log(token) // Will print the token
      })
    },
  },
}
</script>