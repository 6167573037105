<template>
  <v-select
      outlined
      class="ma-0 pa-0 velc-select"
      @change="selectedSystemUpdated"
      v-model="localSelectedSystem"
      :items="systemList"
  >
    <template v-slot:selection="{ item, index }">
      <v-img
          class="m-0"
          style="display: flex; align-items: center; height: 76px !important;"
          :src="item.logoUrl"
          :key="index"
      ></v-img>
    </template>
    <template v-slot:item="{ item }">
      <v-card class="my-1 pa-1 rounded-lg" outlined elevation="0">
        <v-img
            :src="item.logoUrl"
        ></v-img>
      </v-card>
    </template>
  </v-select>
</template>

<script>
import System from "../../model/System";

export default {
  name: 'SystemSelect',

  props: {
    systemList: {
      type: Array,
      required: true,
      validator: function (value) {
        return value.every(item => item instanceof System);
      },
    },

    selectedSystem: {
      required: true,
      validator: function (value) {
        return value instanceof System;
      },
    },
  },

  components: {},

  data: () => ({
    localSelectedSystem: {
      default: [],
      type: System,
    }
  }),

  beforeMount() {
    this.localSelectedSystem = this.selectedSystem
  },

  mounted() {},

  methods: {
    selectedSystemUpdated: function(payload) {
      this.localSelectedSystem = payload
      this.$emit('selectedSystemUpdated', payload)
    },
  }
}
</script>
