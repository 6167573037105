<template>
  <v-scale-transition>
    <v-card class="pa-5 rounded-lg" outlined>
      <div>
        Datos para la transferencia
      </div>
      <br>
      <div v-if="accountsData.length > 1">
        <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Cuenta"
        >
          <v-select
              :items="accountsData"
              v-model="localAccountData"
              label="Cuenta"
              item-text="name"
              item-value="id"
              class="mx-auto"
              dense
              outlined
              required
              :error-messages="errors"
              @change="accountSelected"
          ></v-select>
        </validation-provider>
      </div>
      <div v-if="localAccountData !== null" :key="redrawKey" >
        <validated-outlined-text-input
            @change="accountEmailUpdated"
            label="Cuenta Correo Electrónico"
            :model="localAccountData.accountEmail"
            required
        />
      </div>
    </v-card>
  </v-scale-transition>
</template>

<script>

import {ValidationProvider} from "vee-validate";
import AccountData from "../../../model/AccountData";
import ValidatedOutlinedTextInput from "../../Form/ValidatedOutlinedTextInput";

export default {
  name: "EmailTransferForm",

  components: {
    ValidationProvider,
    ValidatedOutlinedTextInput,
  },

  props: {
    accountsData: {
      type: Array,
      required: true,
      validator: function (value) {
        return value.every(item => item instanceof AccountData);
      },
    },
  },

  data: () => ({
    localAccountData: null,
    redrawKey: 0,
  }),

  beforeMount() {
    if(this.accountsData.length === 1) {
      this.updateAccount(this.accountsData[0])
    } else if (this.accountsData.length === 0) {
      this.updateAccount(AccountData.Blank())
    }
  },

  mounted() {},

  methods: {
    accountSelected(payload) {
      this.updateAccount(this.accountsData.find(accountData => accountData.id === payload))
      this.redrawKey++
    },

    updateAccount(data) {
      this.localAccountData = data
      this.$store.commit('updateStoreValue', { variable: 'account_data', value: this.localAccountData  });
    },

    accountEmailUpdated(payload) {
      this.localAccountData.accountEmail = payload
      this.$store.commit('updateStoreValue', { variable: 'account_data', value: this.localAccountData  });
    },
  },

  computed: {}
}
</script>