<template>
  <v-scale-transition>
    <v-card class="pa-5 rounded-lg" outlined >
      <div>
        Datos para el envío a domicilio
      </div>
      <br>
      <validated-outlined-text-input @change="setAddress" label="Dirección de envío" :model="address" required id="searchPlace" placeholder="" />
      <div id="map" class="map" />
      <br>
      <validated-outlined-text-input @change="setAddressComments" label="Piso/Depto/Timbre" :model="address_comments" required/>
      <validated-outlined-text-input @change="setAddressReceiver" label="Quién lo recibe" :model="address_receiver" required />
      <phone-input-number @change="setReceiverPhone" label="Teléfono de quien lo recibe" :model="address_phone" />
      <div>
        Seleccionar una fecha y hora para el retiro
      </div>
      <v-dialog
          ref="dialog"
          v-model="menu1"
          :return-value.sync="date"
          persistent
          width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <validation-provider
              v-slot="{ errors }"
              :rules="{required: true,}"
          >
            <v-text-field
                v-model="formattedDate"
                label="Hacé click para seleccionar fecha y hora"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </validation-provider>
        </template>
        <v-date-picker
            v-model="date"
            :show-current="true"
            @input="onChangeDate(date)"
            :allowed-dates="allowedDates"
            scrollable
        ></v-date-picker>
      </v-dialog>
    </v-card>
  </v-scale-transition>
</template>

<script>

import PhoneInputNumber from "./PhoneNumberInput";
import ValidatedOutlinedTextInput from "./ValidatedOutlinedTextInput";
import { Loader } from '@googlemaps/js-api-loader'
import Swal from "sweetalert2";
import {ValidationProvider} from "vee-validate";

export default {
  name: "SendForm",

  components: {
    PhoneInputNumber,
    ValidatedOutlinedTextInput,
    ValidationProvider,
  },

  props: {},

  data: () => ({
    address: null,
    address_receiver: null,
    address_phone: null,
    address_lat: null,
    address_lng: null,
    address_price: null,
    address_comments: null,
    map: null,
    loader: null,
    marker: null,
    searchBox: null,

    date: null,
    formattedDate: null,
    now: null,
    allowed: [],
    menu1: false,
  }),

  beforeMount() {
    this.now = new Date(Date.now());
    this.calendar.map(x => this.allowed.push([x['date'].substring(0, 10),x['start_time'],x['end_time']]));
  },

  mounted() {
    this.loader = new Loader({
      apiKey: "AIzaSyAAJl8Nt-ZsPfGWRWvog-RzQmra5jsd3P8",
      version: "weekly",
      libraries: ["places"]
    });

    this.address_price = 1;

    this.createMap();

  },

  methods: {
    onChangeDate(date) {
      this.formattedDate = this.formatDate(date);
      this.$store.commit('setWithdrawalDate', `${this.formattedDate} 17:00:00`);
      this.menu1 = false;
    },

    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

    allowedDates(v) {
      return this.arrayHasElement(this.allowed, v.substring(0, 10));
    },

    arrayHasElement(array, element) {
      return array.filter(e => e[0] === element).length > 0;
    },

    setReceiverPhone(payload) {
      this.address_phone = payload.nationalNumber ?? null;
      this.$store.commit('setReceiverPhone', this.address_phone);
    },

    setAddress(payload) {
      this.address = payload ?? null;
      this.$store.commit('setAddress', this.address);
    },

    setAddressReceiver(payload) {
      this.address_receiver = payload ?? null;
      this.$store.commit('setAddressReceiver', this.address_receiver);
    },

    setAddressComments(payload) {
      this.address_comments = payload ?? null;
      this.$store.commit('setAddressComments', this.address_comments);
    },

    setAddressLat(payload) {
      this.address_lat = payload;
      this.$store.commit('setAddressLat', this.address_lat);
    },

    setAddressLng(payload) {
      this.address_lng = payload;
      this.$store.commit('setAddressLng', this.address_lng);
    },

    setAddressPrice(payload) {
      this.address_price = payload;
      this.$store.commit('setAddressPrice', this.address_price);
    },

    createMap() {
        this.loader.load().then((google) => {

          let coordinates = {lat: -34.6083, lng: -58.3712}

          let map = this.initializeMap(google, coordinates);

          this.searchBox = new google.maps.places.SearchBox(document.getElementById("searchPlace"));

          map.addListener("bounds_changed", () => {
            this.searchBox.setBounds(map.getBounds());
          });

          this.searchBox.addListener("places_changed", () => {
            try {
              const bounds = new google.maps.LatLngBounds();
              const place = this.searchBox.getPlaces()[0];
              const location = this.searchBox.getPlaces()[0].geometry.location;
              const municipality = place.address_components.filter(address => address.types.includes("administrative_area_level_2"))[0].short_name;
              const price = this.municipalities.find(order => order.name === municipality).price;

              bounds.extend(location);
              map.fitBounds(bounds);

              this.setAddress(place.formatted_address);
              this.setAddressLat(location.lat());
              this.setAddressLng(location.lng());
              this.marker = this.createMarker(google, {lat: this.address_lat, lng: this.address_lng}, map)
              this.setAddressPrice(price);
              this.notifyPrice(municipality, price);
            } catch (e) {
              console.log(e);
              this.notifyNoMunicipality();
            }

          });

        }).catch(e => {
          console.log(e);
        });
    },

    initializeMap(google, coordinates) {
      return new google.maps.Map(document.getElementById("map"), {
        center: coordinates,
        zoom: 11,
        mapTypeControl: false,
        streetViewControl: false,
        minZoom: 11,
        maxZoom: 16,
      });
    },

    createMarker(google, coordinates, map) {
      if(this.marker != null) this.marker.setMap(null);

      return new google.maps.Marker({
        position: coordinates,
        map,
      });
    },

    notifyPrice(municipality, price) {
      Swal.fire({
        title: 'Estimado cliente',
        html: 'El envío a ' + municipality + ' tiene un cargo de ' + price + ' ARS. En caso de que el paquete contenga ARG, el costo será retenido del mismo. En caso contrario, coordinar por Whatsapp.',
        icon: 'warning',
        showConfirmButton: false,
        timer: 8000,
        scrollbarPadding: false,
      });
    },

    notifyNoMunicipality() {
      Swal.fire({
        title: 'Estimado cliente',
        html: 'Lamentablemente no realizamos envíos a la zona seleccionada. Coordinar el envío por Whatsapp',
        icon: 'error',
        showConfirmButton: false,
        timer: 8000,
        scrollbarPadding: false,
      });
    }
  },

  computed: {
    municipalities () {return this.$store.state.municipalities;},
    calendar () {return this.$store.state.calendar;},
  }
}
</script>

<style>
html,
body,
#map {
  height: 250px;
  width: 100%;
  margin: 0;
  padding: 0;
}
</style>