<template>
  <v-data-table
      :headers="headers"
      :items="data"
      hide-default-footer
      class="elevation-6 mx-auto py-10 px-4 rounded-lg"
      :header-props="{ sortByText: 'Ordernar por' }"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Cotizaciones más consultadas</v-toolbar-title>
      </v-toolbar>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'RatesMatrix',

  props: {
    rates: {
      type: Array,
      default: [],
    },
  },

  data () {
    return {
      headers: [
        {
          text: 'SISTEMA',
          align: 'start',
          sortable: true,
          value: 'systemFrom',
        },
        { text: 'USD Cash', value: 'usdCashRate' },
        { text: 'Transfer Pesos', value: 'arsTransferRate' },
        { text: 'Pesos Cash', value: 'arsCashRate', sortable: false, },
      ],
      data: [],
    }
  },

  beforeMount() {
    this.populateRates(this.rates);
  },

  watch: {
    "$store.state.rates"(nv) {
      this.populateRates(nv);
    },
  },

  methods: {
    populateRates(rates) {
      this.data = [];

      let nameUsdCash = 'USD EFECTIVO';
      let nameArsTransfer = 'ARS TRANSFER';
      let nameArsCash = 'ARS EFECTIVO';

      let systemNames = [
        'USD PAYO',
        'USD WISE',
        'STABLECOINS',
      ];

      for (let i = 0; i < systemNames.length; i++) {
        let system = rates.find(element => element.name === systemNames[i]);
        let usdCash = system.rates.find(element => element.system_to.name === nameUsdCash);
        let arsTransfer = system.rates.find(element => element.system_to.name === nameArsTransfer);
        let arsCash = system.rates.find(element => element.system_to.name === nameArsCash);

        let rate = {
          systemFrom: system.name,
          usdCashRate: usdCash != null ? usdCash.price : '',
          arsTransferRate: arsTransfer != null ? arsTransfer.price : '',
          arsCashRate: arsCash != null ? arsCash.price : '',
        };
        this.data.push(rate);
      }
    },
  },
}
</script>