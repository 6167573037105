<template>
  <v-row justify="center">
    <v-col
        xs="12"
        sm="10"
        md="8"
        lg="12"
        xl="8"
        class="px-5"
    >
      <div>
        <div class="text-header-1 my-10" >
          Términos y condiciones de uso y navegación del sitio VELC FINANZAS
        </div>
        <div class="text-subheader my-10" >
          <p>Esta es la página web de VELC FINANZAS (en adelante “VELC FINANZAS ”) y los correspondientes Términos y Condiciones Generales, en adelante, “T&C” se aplicarán para la navegación de los USUARIOS en la página web y regulan la relación legal con los servicios ofrecidos en la misma.</p>
          <p>VELC FINANZAS: Plataforma para el intercambio, compra, venta (P2P) de saldos virtuales.</p>
          <p>USUARIO: Las personas que intercambian, compran y venden saldos virtuales a través de VELC FINANZAS.</p>
          <p>SISTEMAS DE ENVÍO: Plataformas o procesos que utilizan los usuarios en cada uno de los sistemas aceptados por VELC FINANZAS para el intercambio de saldos virtuales.</p>
        </div>
        <div class="text-header-1 my-10" >
          Primera. Aceptación de los T&C:
        </div>
        <div class="text-subheader my-10" >
          <p>Todo el contenido legal expresado en los presentes T&C y sus futuras modificaciones son vinculantes para cualquier persona que navegue en la página web. El uso y navegación implica el conocimiento y la aceptación de lo expresado en dicho sitio y en estos términos y condiciones y sus actualizaciones. Si usted no está de acuerdo con los Términos y Condiciones, deberá cancelar su navegación.</p>
          <p>Esta página y las distintas aplicaciones deberán ser visitadas de forma regular a fin de conocer los cambios que se produzcan, sin embargo, notificaremos o intentaremos notificar los cambios en el sitio por medio de los canales habituales.</p>
        </div>

        <div class="text-header-1 my-10" >Segunda. Conceptos básicos de funcionamiento del sitio y funcionamiento de los servicios:</div>
        <div class="text-subheader my-10" >
          <p>El servicio permite a los USUARIOS realizar solicitudes en el sitio sin la obligación de estar registrados operando una aplicación informática en los cuales podrán realizar acciones de intercambio y solicitud de saldos digitales de diferentes aplicaciones.</p>
          <p>TODAS las personas que realicen operaciones en el sitio podrán operar de forma libre pero VELC FINANZAS en determinados momentos podrá requerir información sobre la operatoria en cumplimiento de las normas comerciales vigentes.</p>
          <p>VELC FINANZAS es una entidad independiente que ofrece servicios de compra, cobro, transferencia, intercambio y venta de saldos y monedas digitales. Incluye la selección para realizar operaciones a través de operadores como PayPal, Skrill, Neteller, Bitcoin, RapiPago, PagoFácil, MercadoPago, DAI, etc. sin tener una relación societaria, contractual o laboral con los servicios mencionados.</p>
          <p>VELC FINANZAS no es un banco ni una entidad regulada por la ley de entidades financieras 21.526 ni es una casa de cambios por lo que las transferencias y operaciones de cambio se realizarán a través de entidades reguladas por la normativa vigente.</p>
        </div>

        <div class="text-header-1 my-10" >Tercera. Capacidad legal:</div>
        <div class="text-subheader my-10" >
          <p>Es necesario contar con la edad y la capacidad legal para aceptar los T&C en el lugar donde viva el USUARIO. Si fueren menores, serán representados por sus responsables parentales. Con la lectura o acceso al sistema, cada parte acepta los presentes T&C.</p>
          <p>Los USUARIOS se comprometen a que toda la información volcada sobre sus datos serán fieles y expresas y veraces, de lo contrario serán responsables por el accionar dañoso de la información inexacta o falsa que suministren.</p>
        </div>

        <div class="text-header-1 my-10" >Cuarta. Comisiones y operatoria. División de envíos. Tiempo de proceso de las operaciones:</div>
        <div class="text-subheader my-10" >
          <p>VELC FINANZAS pone a disposición un sistema para realizar envíos de saldos disponibles mediante el pago de comisiones por la operatoria.</p>
          <p>El USUARIO asume los costos de envío en caso que el sistema elegido cobre por ello , que son debitados por la plataforma aparte del envío del dinero. El USUARIO también asume los costos e impuestos por recibir dinero en caso que el sistema elegido cobre por ello.</p>
          <p>En los casos que un USUARIO realice, por pedido de VELC FINANZAS, un envío en dos o más partes pagará la misma comisión como si hubiera realizado una única transacción, corriendo por cuenta de VELC FINANZAS los gastos incurridos por esta división de envío. Al respecto se puede consultar nuestro apartado de preguntas frecuentes.</p>
          <p>Las transferencias y acreditaciones pueden tener demoras de entre 24 a 48 horas hábiles una vez acreditado tu pedido en VELC FINANZAS. Eventualmente puede haber demoras mayores por gran demanda o por el sistema propio de recepción como en cualquier operatoria tradicional.</p>
          <p>COSTOS DE ENVÍO: Las siguientes plataformas pueden cobrar costos de envío. Se recomienda revisar los Términos y condiciones de cada una y su operatoria.</p>
          <p>PLATAFORMAS ACEPTADAS: Las plataformas o procesos aceptados serán los que se describan y estén disponibles en la calculadora de operaciones de VELC FINANZAS.</p>
          <p>IMPUESTOS: Algunas plataformas podrían cobrar impuestos por sus operatorias. Se recomienda revisar los Términos y condiciones de cada y su operatoria en el país donde seas residente.</p>
        </div>

        <div class="text-header-1 my-10" >Quinta. Prohibiciones totales y parciales:</div>
        <div class="text-subheader my-10" >
          <p>a. Los USUARIOS de VELC FINANZAS no podrán en forma absoluta realizar acciones de compraventa de productos no aptos ni prohibidos para el comercio.</p>
          <p>b. Las VCC (Tarjetas virtuales de crédito) no pueden ser utilizadas para realizar operaciones de compra o pago de servicios. Se recomienda leer el apartado de preguntas frecuentes sobre este tema.</p>
          <p>c. Se encuentra prohibido realizar reclamaciones a las contrapartes sin la previa autorización de VELC FINANZAS. La apertura de estas reclamaciones debe hacerse en forma exclusiva con VELC FINANZAS en virtud de que al USUARIO no lo une ninguna relación comercial con la contraparte.</p>
        </div>

        <div class="text-header-1 my-10" >Sexta. Procesos y conflictos en usos de VELC FINANZAS. Compra de productos o servicios:</div>
        <div class="text-subheader my-10" >
          <p>En cualquier caso que los USUARIOS de VELC FINANZAS realicen la compra de un producto o servicio ajeno a VELC FINANZAS se deja en claro que VELC FINANZAS no participa del proceso de compra y venta y/o entrega del producto a enviar o cumplimiento de los servicios, por lo cual esta transacción es ajena a la empresa.</p>
          <p>En caso de que el USUARIO considere que ha existido un mal accionar por parte de otro USUARIO, VELC FINANZAS pone a su disposición un ticket de notificación del problema mediante correo electrónico y además deberá calificar la operación de forma tal que quede registrado el problema sucedido. VELC FINANZAS dará respuesta a dicho evento en los plazos indicados.</p>
          <p>Es responsabilidad del USUARIO declarar correctamente y verificar los datos de la cuenta de destino para el envío de saldos. En caso de equivocarse o no haberla verificado correctamente no podrá realizar un reclamo. VELC FINANZAS no puede revertir la operación errónea por errores de tipeo o errores de datos enviados.</p>
        </div>

        <div class="text-header-1 my-10" >Séptima. Enlace de retracto o arrepentimiento. Operaciones en proceso:</div>
        <div class="text-subheader my-10" >
          <p>El USUARIO podrá cancelar la oferta sobre el servicio contratado dentro del plazo de diez (10) días posteriores desde la recepción del código por mail en virtud de lo establecido por la Ley de Defensa del Consumidor.</p>
          <p>Al ser un servicio de intermediación EL USUARIO podrá cancelarlo en tanto y en cuanto no haya enviado el saldo. Una vez enviada la operación no podrá anularse.</p>
          <p>EL USUARIO o VELC FINANZAS podrán realizar las cancelaciones antes de realizar las operaciones de intercambio de saldos en razón de las cotizaciones o a criterio de VELC FINANZAS por incumplimiento de estos términos y condiciones.</p>
          <p>En los casos que la operación de intercambio de saldos ya fue realizada no existe posibilidad de revertir la misma una vez enviado el saldo, ni de operar con un ticket de reclamo.</p>
        </div>

        <div class="text-header-1 my-10" >Octava. Operaciones bancarias. Operaciones con saldo retenido. Operaciones con saldo limitado:</div>
        <div class="text-subheader my-10" >
          <p>Los USUARIOS que no posean transferencia bancaria inmediata en su entidad bancaria argentina, pueden solicitar una cuenta destino especial. Esta operatoria tendrá un recargo del cinco (5%) de la cotización original.</p>
          <p>VELC FINANZAS será responsable de enviar el saldo acordado a la cuenta y por el método elegido por el USUARIO, en una o varias partes. El USUARIO es responsable de la carga correcta de datos, el estado correcto de la cuenta destino y las demoras en la disponibilidad del saldo.</p>
          <p>En los casos con saldo retenido se informa que las retenciones no son definidas o establecidas por VELC FINANZAS. Si el USUARIO por cualquier motivo recibe una retención de saldos deberá consultar la sección de preguntas frecuentes para informarse sobre los distintos procedimientos de las plataformas.</p>
          <p>VELC FINANZAS en los casos de saldos limitados se reserva el derecho a devolver el saldo enviado, restando las comisiones incurridas, si no está disponible completamente para todo tipo de operaciones. Este el caso, sin limitar, de Skrill o Neteller que provienen de fondos de tarjeta. VELC FINANZAS se compromete a comunicarlo previamente y claramente antes del envío del usuario. Al respecto y para mayor información recomendamos consultar la sección de preguntas frecuentes.</p>
        </div>

        <div class="text-header-1 my-10" >Novena. Errores y procesos especiales:</div>
        <div class="text-subheader my-10" >
          <p>Se deja aclarado que en los casos que: a. Por equivocación o error en el destinatarios del saldo. b. Por equivocación en las instrucciones informadas por VELC FINANZAS en el mail de información de la operación c. Por equivocación en los montos informados dentro del proceso informado por VELC FINANZAS. Al respecto y para mayor información recomendamos consultar estos términos y condiciones y las preguntas fuentes.</p>
          <p>Los USUARIOS podrán realizar todas las acciones en el sitio, en determinados casos según la necesidad y por estrictas razones de seguridad podrán realizarse procesos de verificación de información con los mejores medios disponibles a fin de conocer e identificarlos. Por esta razón podremos solicitar información o datos adicionales para seguridad de las operaciones.</p>
        </div>

        <div class="text-header-1 my-10" >Décima. KYC de VELC FINANZAS (Lo que conocemos sobre vos):</div>
        <div class="text-subheader my-10" >
          <p>En caso de necesidad de conocer la información de los USUARIOS, se podrá solicitar para el proceso de verificación los siguientes documentos y/o justificaciones: a. Copia de su DNI. b. Servicios a su nombre o documentación adicional. c. Una fotografía a fin de poder identificar su cuenta. d. Teléfono fijo y horarios para recibir llamadas. e. Video llamada con grabación para validar. f. Formulario de aceptación de pago. g. Explicación de actividad comercial y su vínculo con el saldo electrónico. h. Copias, datos o reportes de extractos bancarios o de movimientos de las cuentas.</p>
          <p>VELC FINANZAS podrá suspender temporal o en forma definitiva la cuenta en los siguientes casos: a. Si existe o se detecta información falsa o inexacta en los procesos de verificación. b. Si existiese información no consistente con lo declarado. c. Denuncia de fraude de algunas de las operaciones. d. En caso de haber remitido información falsa o inexacta, engañosa, parcial, adulterada, robo de datos y/o cualquier otro caso de información insuficiente o falsa. e. En caso de recibir una orden judicial o administrativa por violación de leyes, normas o reglamentos o por violación a estos términos y condiciones. f. Uso abusivo y/o malintencionado de la plataforma. g. No acepta enviar documentación solicitada. h. Infringe alguno de los términos y condiciones.</p>
          <p>En estos casos VELC FINANZAS podrá además solicitar aclaraciones y complementación de información. Si el usuario no remitiese la información, la cuenta quedará como suspendida hasta el cumplimiento. VELC FINANZAS realizará y será diligente en llevar adelante todas las acciones y protecciones necesarias a fin de evitar cualquier conflicto y complicaciones con sus USUARIOS. VELC FINANZAS, en base a documentación provista o evaluación del caso, se reserva el derecho de reducir proporcionalmente la operación por motivos de seguridad. Estas no serán sujetas a reclamos por parte de los USUARIOS.</p>
          <p>Los USUARIOS recibirán una calificación cuando se concrete la operación, esta calificación será numeral e indicará el cumplimiento del proceso. Las calificaciones serán por la realización del proceso. Si los USUARIOS tuviesen algún conflicto en cualquier parte del proceso dentro del sitio podrán iniciar en un proceso de resolución de disputas interno, respondiendo el correo del pedido realizado, y VELC FINANZAS observará las mejores prácticas para resolver el mismo y emitirá una decisión fundada a fin de componer a las partes.</p>
        </div>

        <div class="text-header-1 my-10" >Décima primera. Gamificación. Scoring:</div>
        <div class="text-subheader my-10" >
          <p>VELC FINANZAS, en base a la calificación calculada, asignará un valor numérico que va desde el no 1 al 5 denominado “Nivel de usuario”. La calificación se calcula en base, sin limitarse, al compromiso del usuario en cumplir las instrucciones solicitadas, el tiempo en realizarlas, la calidad y cantidad de documentación enviada, el nivel de huella digital en Internet (datos públicos, datos publicados por otros, datos que el mismo usuario publica), la actividad comercial, en el cumplimiento del usuario de las instrucciones.</p>
          <p>De acuerdo al nivel de usuario VELC FINANZAS provee acceso a funcionalidades como ofertas o priorización de pagos, entre otras. En algunos casos y en base a la información brindada a VELC FINANZAS podremos contactar a las personas que mencionaste en el proceso de verificación o el proceso de seguridad de la información o datos del proceso de intercambio de saldos. En estos casos nos comunicaremos con las personas informando de la situación y compartiendo la operación controvertida realizada por el USUARIO.</p>
        </div>

        <div class="text-header-1 my-10" >Décima segunda. Uso del sitio y prohibiciones:</div>
        <div class="text-subheader my-10" >
          <p>Los usuarios del sitio VELC FINANZAS aceptan no publicar y no podrán realizar las siguientes acciones: a. Enviar comentarios con contenidos ilegales, obscenos, abusivos, difamatorios, injuriosos, discriminatorios, vejatorios o contrario a las buenas costumbres. b. Enviar archivos o comunicaciones que contengan virus, troyanos, programa maligno o cualquier otra característica capaz de dañar el funcionamiento de un sistema informático. c. Ofrecer otros productos o servicios distintos a los autorizados en la aplicación. d. Realizar copia de imágenes, contenidos, diseños o marcas de los productos o violar la propiedad intelectual de VELC FINANZAS o terceros. e. Usar software para monitorear o copiar información, datos o contenidos de la página web, aplicación sin la previa autorización de VELC FINANZAS. f. Usar en todo o en parte las marcas, slogans, frases, imágenes de VELC FINANZAS para realizar acciones de promoción de sus productos o servicios. g. Incumplir con las normas de comunidad de las redes sociales o canales de comunicación que se determinen a tal efecto. h. Incumplir con las políticas de privacidad.</p>
        </div>

        <div class="text-header-1 my-10" >Décima tercera. Seguridad y manejo de la información de acceso:</div>
        <div class="text-subheader my-10" >
          <p>La seguridad es muy importante para VELC FINANZAS, por esta razón el USUARIO deberá observar todas las medidas de seguridad tanto legales como informáticas. La cuenta es personal e intransferible y debido a esto los USUARIOS no pueden ceder su uso a terceros. No obstante, serán responsables por el acceso que les den a terceros de sus cuentas y acceso. VELC FINANZAS no brindará información sobre los procesos o procedimientos a entidades recaudatorias, bancos o plataformas asegurando la protección y la privacidad de los datos.</p>
        </div>

        <div class="text-header-1 my-10" >Décima cuarta. Garantía a clientes. Bonificaciones. Seguros:</div>
        <div class="text-subheader my-10" >
          <p>VELC FINANZAS podrá a su discreción realizar acciones para entrega de premios, bonificaciones y/o descuentos sobre el uso de las cuentas o la operatoria.</p>
        </div>

        <div class="text-header-1 my-10" >Décima quinta. Indemnidad:</div>
        <div class="text-subheader my-10" >
          <p>Los USUARIOS de VELC FINANZAS aceptan mantener indemne a la empresa de cualquier tipo de reclamación, responsabilidad, pérdida, gastos, honorarios técnicos y legales, solicitudes y remisión de información por incumplimientos en estos términos y condiciones, con exclusión de lo establecido en la cláusula de seguros.</p>
        </div>

        <div class="text-header-1 my-10" >Décima sexta. Transferencia de derechos. Uso personal de la cuenta:</div>
        <div class="text-subheader my-10" >
          <p>EL USUARIO acepta que la operación y acciones de la cuenta son personales y que debe ser el titular de la cuenta del origen de los fondos. El USUARIO no podrá transferir sus derechos u obligaciones a terceras personas sin la previa conformidad de VELC FINANZAS.</p>
        </div>

        <div class="text-header-1 my-10" >Décima séptima. Carácter contractual. Redes sociales. Marca:</div>
        <div class="text-subheader my-10" >
          <p>VELC FINANZAS declara que no lo une ninguna relación asociativa y de vinculación legal con las empresas que puedan intervenir en los procesos de recepción, movimiento, entrega y/o pagos o de otras páginas web que intervengan en el proceso. VELC FINANZAS no será responsable de la precisión, disponibilidad o confiabilidad de cualquier información, contenido, datos, opiniones, aclaraciones o declaraciones insertas en redes sociales realizadas por terceros. El uso y comunicación de contenidos por parte de los usuarios en las redes sociales quedará determinado y será responsabilidad del usuario que lo haya publicado. VELC FINANZAS Es una marca registrada y para los usos promocionales o publicitarios de sus marcas se deberá observar los usos autorizados de la misma.</p>
        </div>

        <div class="text-header-1 my-10" >Décima octava. Método de pago online. Marcas de terceros. Recibos:</div>
        <div class="text-subheader my-10" >
          <p>VELC FINANZAS utilizará los diferentes sistemas con el fin de poder realizar los pagos que se hagan en la plataforma. VELC FINANZAS no tiene ninguna vinculación y/o afiliación comercial con los nombres y marcas comerciales que aparecen en el sitio. Por lo que las menciones son con carácter meramente informativo.</p>
          <p>Los USUARIOS que realicen operaciones en el sitio podrán obtener facturas [A y B] según su registro y su categoría fiscal. En los casos que los usuarios carguen los datos como usuarios de Factura A, serán emitidas a partir de la carga de datos y no antes. VELC FINANZAS emitirá la factura por el servicio ofrecido una vez finalizado tu pedido, en Pesos Argentinos, correspondiente al 3% del monto enviado.No se podrá facturar a la plataforma VELC FINANZAS por el el valor de intercambio de saldos. En casos que se observa una facturación sobre montos intercambiados con otro USUARIO VELC FINANZAS rechazará el proceso de envío de la factura y reclamará por los daños y perjuicios ocasionados.</p>
        </div>

        <div class="text-header-1 my-10" >Décima novena. Contacto sobre operaciones y soporte técnico:</div>
        <div class="text-subheader my-10" >
          <p>En caso de contacto sobre una operación el USUARIO deberá responder el mail recibido con los detalles de la solicitud de saldo. [SOLO EN CASO DE CONSULTAS TÉCNICAS] En casos de necesidad o ayuda con procesos técnicos o posventa de VELC FINANZAS se pondrá a disposición de los usuarios del sitio web los métodos de contacto que a continuación se detallan: CONTACTO: ayuda@velcfinanzas.com DATOS: SERVICIO DE AYUDA AL USUARIO DE VELC FINANZAS VELC FINANZAS | velcfinanzas.com.</p>
        </div>

        <div class="text-header-1 my-10" >Vigésima. Propiedad intelectual. Derecho de imagen:</div>
        <div class="text-subheader my-10" >
          <p>a. Los Usuarios aceptan que todos los derechos de propiedad intelectual son propiedad de VELC FINANZAS , así como las marcas, patentes, derechos de autor, modelos y diseños industriales, trade dress, apariencia, logos, dibujos, iconos son también propiedad intelectual de VELC FINANZAS.</p>
          <p>b. Sobre los contenidos u otros materiales que son o pudiesen ser puestos a disposición en la página de VELC FINANZAS se declara y garantiza que se posee todos los derechos, títulos y autorizaciones entre otros, de los derechos de autor y de publicidad.</p>
          <p>c. En los casos que se publiquen imágenes de personas VELC FINANZAS solicitará la autorización y cesión respectiva. En los casos que sean subidas por el usuario este será responsable de dicha puesta a disposición.</p>
          <p>d. La páginas de acceso al sistema serán https://velcfinanzas.com como así cualquiera de sus subdominios.</p>
        </div>

        <div class="text-header-1 my-10" >Vigésima primera. Datos personales y políticas de privacidad:</div>
        <div class="text-subheader my-10" >
          <p>a. VELC FINANZAS observará los más altos estándares de protección de los datos personales de sus usuarios y pondrá a disposición de estos sus políticas de privacidad y cumplimiento de protección de datos.</p>
          <p>b. VELC FINANZAS declara que posee registros sobre sus bases de datos de clientes y proveedores.</p>
          <p>c. VELC FINANZAS declara que el encargado del control de su base de datos es Bitmerang S.A.</p>
          <p>d. Los datos y su manejo además cuentan con la protección de nuestras políticas de privacidad que complementan lo aquí expresado.</p>
          <p>e. Cualquier consulta con respecto a datos personales puede hacerla en la Dirección de protección de datos personales dependiente de la Agencia de acceso a la información pública (https://www.argentina.gob.ar/aaip/datospersonales).</p>
          <p>f. DATOS COMPARTIDOS EN LA OPERATORIAS: Se deja aclarado que VELC FINANZAS deberá compartir datos necesarios para realizar la operatoria. En este caso el USUARIO acepta compartirlos para la realización de la operación de intercambio de saldos. Si el USUARIO decidiese compartir algún dato deberá abstenerse de utilizar la plataforma.</p>
        </div>

        <div class="text-header-1 my-10" >Vigésima segunda. Datos personales y políticas de privacidad:</div>
        <div class="text-subheader my-10" >
          <p>De producirse cualquier conflicto legal en el uso del sitio o del acceso a la página web o sobre cualquier conflicto con los T&C el usuario deberá remitir única y exclusivamente por mail a la dirección legales@velcfinanzas.com o al sistema de contacto provisto. El uso del sitio se proporciona según se encuentra al momento de uso, con todas sus funcionalidades y especificaciones. VELC FINANZAS ofrece el cumplimiento de las normas con la mayor seguridad aplicable posible. Si tiene dudas, sugerencias o reclamos puede hacerlos llegar a legales@velcfinanzas.com</p>
        </div>

        <div class="text-header-1 my-10" >Vigésima tercera. Normativa y comunicaciones del banco central. Operaciones financieras. Operaciones con criptomonedas:</div>
        <div class="text-subheader my-10" >
          <p>Por la operatoria propia de VELC FINANZAS no se encuentra regulada por normativa del Banco Central ni por sus comunicaciones. VELC FINANZAS no es considerado en los términos de las leyes argentinas generales como un banco ni una entidad regulada por la ley de entidades financieras 21.526. Tampoco es considerada una casa de cambios. Las transferencias y operaciones de cambio se realizarán a través de entidades reguladas por la normativa vigente. Los saldos digitales en cuentas son activos digitales regulados por las leyes generales y no son considerados operaciones financieras. No obstante VELC FINANZAS observará todas las medidas de seguridad para el manejo seguro de dichos saldos.La operatoria de criptomonedas será una responsabilidad propia de cada uno de los usuarios y los usuarios declaran conocer todas y cada unas de las regulaciones que rigen a las criptomonedas en Argentina.</p>
        </div>

        <div class="text-header-1 my-10" >Vigésima cuarta. Legislaciones y competencia:</div>
        <div class="text-subheader my-10" >
          <p>Todas las controversias o litigios derivados del uso del servicio de VELC FINANZAS serán sometidas a la jurisdicción de la Justicia Nacional en lo Comercial con asiento en la Ciudad Autónoma de Buenos Aires, República Argentina. Estos Términos y Condiciones se regirán por las leyes de la República Argentina. Al contratar nuestros servicios usted declara conocer y aceptar estas políticas de uso términos y condiciones.</p>
          <p>Dirección de defensa al consumidor de la Ciudad de Buenos Aires: https://www.buenosaires.gob.ar/defensaconsumidor/direccion-general</p>
          <p>Para contactar a la Dirección Nacional de Protección de Datos Personales: Sarmiento 1118, 5º piso (C1041AAX). Tel: 4383-8510/12/13/15. https://www.argentina.gob.ar/aaip/datospersonales - infodnpdp@just.gov.ar</p>
        </div>


      </div>
    </v-col>
  </v-row>
</template>

<script>
import '../main.css';

export default {
  name: 'Terms',

  data: () => ({
    //
  }),
};
</script>