<template>
  <v-container fluid class="full-height">
    <v-scale-transition>
      <loader v-if="loading"/>
    </v-scale-transition>
    <v-scale-transition>
      <div v-if="!loading" class="mt-10">
        <dynamic-row>
          <dynamic-column aspect_ratio="full">
            <v-row>
              <rounded-container text="PREGUNTAS FRECUENTES"></rounded-container>
            </v-row>
          </dynamic-column>

        </dynamic-row>
        <dynamic-row>
          <v-row justify="center" class="mx-0 fill-height">
            <dynamic-column aspect_ratio="full">
              <FAQContainer
                  v-for="(item, index) in faqs"
                  :key="item.id"
                  :height="300"
                  :width="400"
                  :title="item.title"
                  :description="item.text"
              />
            </dynamic-column>
          </v-row>
        </dynamic-row>
      </div>
    </v-scale-transition>
  </v-container>
</template>

<script>
import FAQContainer from '../components/FAQs/FAQContainer';
import Loader from '../components/Common/Loader';
import DynamicRow from '../components/Common/DynamicRow';
import DynamicColumn from '../components/Common/DynamicColumn';
import RoundedContainer from '../components/FAQs/RoundedContainer';
const axios = require('axios').default;
import '../main.css';

export default {
  name: 'FAQs',

  components: {
    FAQContainer,
    Loader,
    DynamicRow,
    DynamicColumn,
    RoundedContainer,
  },

  data: () => ({
    loading: true,
    faqs: [],
  }),

  mounted() {
    this.loading = true;
    this.getFAQs();
  },

  methods: {
    getFAQs() {
      axios
          .get(process.env.VUE_APP_API_URL + '/api/faqs')
          .then(response => {
            /*response.data = response.data.map(function(faq) {
              return {...faq, faqs:faq.title}
            });*/

            this.faqs = response.data;

            this.loading = false;
          })
          .catch(error => {
            console.log(error)
          })
    },
  },
}
</script>