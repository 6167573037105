<template>
  <v-stepper v-model="e1" style="width:100%" alt-labels class="elevation-6">
    <v-stepper-header>
      <v-stepper-step
          :complete="e1 > 1"
          step="1"
      >
        <div>
          <v-row>
            <v-col
                align="center"
                class="stepper-label-font"
            >
              <v-icon v-if="mobile" color="#006837" >mdi-form-select</v-icon>
              <div v-else>Completás tu pedido</div>
            </v-col>
          </v-row>
        </div>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
          :complete="e1 > 2"
          step="2"
      >
        <div>
          <v-row>
            <v-col
                align="center"
                class="stepper-label-font"
            >
              <v-icon v-if="mobile" color="#006837" >mdi-email-alert-outline</v-icon>
              <div v-else>Recibís las instrucciones</div>
            </v-col>
          </v-row>
        </div>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3">
        <div>
          <v-row>
            <v-col
                align="center"
                class="stepper-label-font"
            >
              <v-icon v-if="mobile" color="#006837" >mdi-cash-multiple</v-icon>
              <div v-else>Enviamos tu saldo</div>
            </v-col>
          </v-row>
        </div>
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <!--<velc-form/>-->
        <data-form
            :user-data="userData"
            :shipment-data="shipmentData"
            :accounts-data="accountsData"
        />
      </v-stepper-content>

      <v-stepper-content step="2">
      </v-stepper-content>

      <v-stepper-content step="3">
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
  import VelcForm from "../Form/Form";
  import '../../main.css';
  import DataForm from "../data/DataForm";
  import ShipmentData from "../../model/ShipmentData";

  export default {

    name: "Stepper",

    props: {
      userData: Object,
      shipmentData: Object,
      accountsData: Array,
    },

    components: {
      VelcForm,
      DataForm,
    },

    data() {
      return {
        e1: 1,
        mobile: false,
      }
    },

    beforeMount() {
      this.shipmentTypeList = this.$store.state.shipment_type_list
    },

    mounted() {
      this.mobile = this.isMobile;
    },

    watch: {
      "$store.state.window.mobile"(nv) {
        this.mobile = nv;
      },
    },

    computed: {
      isMobile () {
        return this.$store.state.window.mobile;
      },
    },
  }
</script>