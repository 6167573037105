<template>
  <div class="mt-4">
    <div class="rectangular-component background-color-primary-shade5 d-flex text-center">
      <v-col cols="8" class="d-flex text-center">
        <div class="text font-weight-bold color-primary">
          {{ text }}
        </div>
      </v-col>
      <v-col cols="4" class="image">
        <v-img
            contain
            :src="svgAsset"
            transition="scale-transition"
            height="100"
            width="100"
        />
      </v-col>
    </div>
  </div>
</template>

<script>
import '../../main.css';

export default {
  name: 'Service',
  props: {
    text: {
      type: String,
      required: true
    },
    svgAsset: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.rectangular-component {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 10px;
  border-radius: 10px;
}

.text {
  flex: 2;
}

.image {
  flex: 1;
}
</style>