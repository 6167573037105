<template>
  <div :style="{ padding: '16px' }">
    <v-row>
      <div class="bullet-container">
        <div class="bullet background-color-primary"></div>
      </div>
      <v-col style="display: flex; flex-direction: column; ">
        <h2 class="color-primary">{{ title }}</h2>
        <p v-html="this.description"></p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'FAQContainer',
  props: {
    title: {
      type: String,
      required: true
    },

    description: {
      type: String,
      required: true
    }
  }
}
</script>
<style>
.title-description {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.bullet-container {
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: 20px;
}

.bullet {
  width: 20px;
  height: 20px;
  background-color: green;
  border-radius: 50%;
}
</style>