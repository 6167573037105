<template>
  <v-footer v-bind:class="{
            'dynamic-footer-web': !isMobile,
            'dynamic-footer-mobile': isMobile
          }" color="primary">
    <v-row justify="center">
      <v-col
          class="px-0"
          xs="12"
          sm="10"
          md="8"
          lg="12"
          xl="8"
      >
        <v-row class="justify-end align-center">
          <v-btn icon href="https://www.facebook.com/profile.php?id=100077579496095" target="_blank" rel="noopener noreferrer" style="background-color: white" class="mr-4">
            <v-icon size="45" color="indigo">mdi-facebook</v-icon>
          </v-btn>
          <v-btn icon href="https://www.instagram.com/velcfinanzas" target="_blank" rel="noopener noreferrer" class="mr-4">
            <v-icon size="45" color="red">mdi-instagram</v-icon>
          </v-btn>
          <div style="width: 150px;">
            <v-img
                alt="Velc Logo"
                contain
                :src="require('../../../src/assets/logo.svg')"
                transition="scale-transition"
                height="50"
                class="mx-0"
            />
          </div>
        </v-row>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: 'DynamicFooter',

  data: () => ({

  }),

  mounted() {},

  watch: {
    "$store.state.window.mobile"(nv) {
      this.mobile = nv;
    },
  },

  computed: {
    isMobile () {
      return this.$store.state.window.mobile;
    },
  },
}
</script>
<style>
  .dynamic-footer-web {
    height: 60px;
  }
  .dynamic-footer-mobile {
    height: 50px;
  }
</style>