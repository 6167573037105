export default class AccountData {
    constructor(data) {
        this.type = data.type.value || '';
        this.id = data.id || '';
        this.name = data.name || '';
        this.transferBank = data.transfer_bank || '';
        this.transferHolder = data.transfer_holder || '';
        this.transferAlias = data.transfer_alias || '';
        this.transferCuit = data.transfer_cuit || '';
        this.transferCbu = data.transfer_cbu || '';
        this.transferAccountNumber = data.transfer_account_number || '';
        this.walletAddress = data.wallet_adress || '';
        this.accountEmail = data.account_email || '';
    }

    static Blank() {
        return new AccountData({
            type: '',
            id: '',
            name: '',
            transfer_bank: '',
            transfer_holder: '',
            transfer_alias: '',
            transfer_cuit: '',
            transfer_cbu: '',
            transfer_account_number: '',
            wallet_adress: '',
            account_email: '',
        });
    }
}