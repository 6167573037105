<template>
  <v-scale-transition>
    <v-card class="pa-5 rounded-lg" outlined>
      <div>
        Datos para la transferencia
      </div>
      <br>
      <div v-if="accountsData.length > 1">
        <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Cuenta"
        >
          <v-select
              :items="accountsData"
              v-model="localAccountData"
              label="Cuenta"
              item-text="name"
              item-value="id"
              class="mx-auto"
              dense
              outlined
              required
              :error-messages="errors"
              @change="accountSelected"
          ></v-select>
        </validation-provider>
      </div>
      <validation-provider
          v-slot="{ errors }"
          rules="required"
          name="Tipo de cuenta"
      >
        <v-select
            :items="options"
            v-model="selectedOption"
            label="Tipo de cuenta"
            item-text="label"
            item-value="value"
            class="mx-auto"
            dense
            outlined
            required
            :error-messages="errors"
            @change="updateSelection"
        ></v-select>
      </validation-provider>
          <div v-if="localAccountData !== null" :key="redrawKey" >
            <validated-outlined-text-input
                @change="walletAddressUpdated"
                label="Dirección Tether USDT"
                :model="localAccountData.walletAddress"
                hint="Por ejemplo TMx5KvqyTbnFBM70vyHo9awHCoqpQgua99"
                required
            />
          </div>
    </v-card>
  </v-scale-transition>
</template>

<script>

import {extend, ValidationProvider} from "vee-validate";
import AccountData from "../../../model/AccountData";
import ValidatedOutlinedTextInput from "../../Form/ValidatedOutlinedTextInput";
import {required} from "vee-validate/dist/rules";

extend('required', {
  ...required,
  message: 'Este campo es requerido',
})

export default {
  name: "WalletTransferForm",

  components: {
    ValidationProvider,
    ValidatedOutlinedTextInput,
  },

  props: {
    accountsData: {
      type: Array,
      required: true,
      validator: function (value) {
        return value.every(item => item instanceof AccountData);
      },
    },
  },

  data: () => ({
    localAccountData: null,
    redrawKey: 0,
    options: [
      {
        label: 'TRC20',
        value: 1,
      },
      {
        label: 'ERC20',
        value: 2,
      },
      {
        label: 'BEP20',
        value: 3,
      },
    ],
    selectedOption: null,
  }),

  beforeMount() {
    if(this.accountsData.length === 1) {
      this.updateAccount(this.accountsData[0])
    } else if (this.accountsData.length === 0) {
      this.updateAccount(AccountData.Blank())
    }
  },

  mounted() {},

  methods: {
    accountSelected(payload) {
      this.updateAccount(this.accountsData.find(accountData => accountData.id === payload))
      this.redrawKey++
    },

    updateAccount(data) {
      this.localAccountData = data
      this.$store.commit('updateStoreValue', { variable: 'account_data', value: this.localAccountData  });
    },

    walletAddressUpdated(payload) {
      this.localAccountData.walletAddress = payload
      this.$store.commit('updateStoreValue', { variable: 'account_data', value: this.localAccountData  });
    },

    updateSelection(payload) {
      this.selectedOption = payload
      this.$store.commit('updateStoreValue', { variable: 'account_type', value: this.options.find(option => option.value === this.selectedOption).label  });
    }
  },

  computed: {}
}
</script>