<template>
  <v-card elevation="6" class="rounded-lg" v-if="!mobile" >
    <slot></slot>
  </v-card>
  <div v-else >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "WebCardColumn",

  data: () => ({
    mobile: false,
  }),

  beforeMount() {
    this.mobile = this.isMobile;
  },

  watch: {
    "$store.state.window.mobile"(nv) {
      this.mobile = nv;
    },
  },

  computed: {
    isMobile () {
      return this.$store.state.window.mobile;
    },
  },
}
</script>