<template>
  <v-data-table
      :headers="headers"
      :items="data"
      hide-default-footer
      class="elevation-6 mx-auto py-10 px-4 rounded-lg"
  ></v-data-table>
</template>

<script>
export default {
  name: 'RatesTable',

  props: {
    rates: Array,
  },

  data () {
    return {
      headers: [
        {
          text: 'Sistema',
          align: 'start',
          sortable: true,
          value: 'systemFrom',
        },
        //{ text: 'Moneda', value: 'currencyFrom' },
        { text: 'Destino', value: 'systemTo' },
        //{ text: 'Moneda destino', value: 'currencyTo' },
        { text: 'Cambio', value: 'rate' },
        { text: 'Ejemplo', value: 'example', sortable: false, },
      ],
      data: [],
    }
  },

  beforeMount() {
    this.populateRates(this.rates);
  },

  watch: {
    "$store.state.rates"(nv) {
      this.populateRates(nv);
    },
  },

  methods: {
    populateRates(rates) {
      this.data = [];
      for (let i = 0; i < rates.length; i++) {
        if(rates[i].can_receive){
          for (let j = 0; j < rates[i].rates.length; j++) {
            if(rates[i].rates[j].system_to.can_send) {
              let systemTo = rates.find(element => element.value === rates[i].rates[j].system_to.id)
              let ratePrice = Number(rates[i].rates[j].type) === 1 ? rates[i].rates[j].price : (1 / rates[i].rates[j].price);
              let amount = ratePrice >= 1 ? 1 : 1 / ratePrice;
              let convertedAmount = amount * ratePrice;
              let rate = {
                systemFrom: rates[i].name + ' (' + rates[i].currency.abbreviation + ')',
                //currencyFrom: rates[i].currency.abbreviation,
                systemTo: rates[i].rates[j].system_to.name + ' (' + systemTo.currency.abbreviation + ')',
                //currencyTo: systemTo.currency.abbreviation,
                rate: ratePrice,
                example: 'Vos enviás ' + amount + ' ' + rates[i].currency.abbreviation + ' y recibís ' + convertedAmount + ' ' + systemTo.currency.abbreviation,
              };
              this.data.push(rate);
            }
          }
        }
      }
    },
  },
}
</script>