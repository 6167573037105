<template>
  <transition name="fade" class="overhead-tip pa-5">
    <div v-if="text">
      <div class="mx-auto px-3 text-field-error overhead-tip" style="height: 20px;">{{ text }}</div>
      <div class="triangle-down"></div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'ErrorBubble',

  props: {
    text: {
      type: String,
      required: false,
    },
  },

  components: {},

  data: () => ({}),

  beforeMount() {},

  mounted() {},

  methods: {}
}
</script>

<style scoped>
.overhead-tip{
  position: absolute !important;
  left: 15px !important;
  top: -7px !important;
  background-color: var(--color-error-background) !important;
  border-radius: 5px;
}
.triangle-down {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid var(--color-error-background);
  border-bottom: 10px solid transparent;
  display: inline-block;
  position: absolute;
  left: 30px !important;
  top:13px !important;
  color: var(--color-error);
}
.text-field-error {
  font-size: 0.8em;
  color: var(--color-error);
}
</style>