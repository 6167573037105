<template>
  <div>
    <VuePhoneNumberInput
        v-model="phone"
        required
        outlined
        dense
        :default-country-code="countryCode"
        no-validator-state
        color="#006837"
        valid-color="black"
        error-color="#b71c1c"
        v-bind="vuePhone.props"
        @update="onUpdatePhone"
    /><br>
  </div>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';

export default {
  name: "PhoneInputNumber",

  props: {
    label: String,

    model: {
      default: null,
      type: String
    },

    areaCode: {
      default: null,
      type: String,
    },
  },

  data: () => ({
    phone: null,

    vuePhone: {
      phone: null,
      addressPhone: null,
      props: {
        clearable: true,
        onlyCountries: ["US","CA","PE","MX","AR","BR","CL","CO","VE","BO","EC","PY","UY","AU","CN","ES","IT","DE","FR","PT","CH","EN"],
        noExample: true,
        required: true,
        translations: {
          countrySelectorLabel: "Código",
          countrySelectorError: "Error",
          phoneNumberLabel: "Teléfono",
          example: "Ejemplo:"
        }
      },
    },

    countriesWithPrefix: [
      { country_code: "US", country_prefix: "1" },
      { country_code: "CA", country_prefix: "1" },
      { country_code: "PE", country_prefix: "51" },
      { country_code: "MX", country_prefix: "52" },
      { country_code: "AR", country_prefix: "54" },
      { country_code: "BR", country_prefix: "55" },
      { country_code: "CL", country_prefix: "56" },
      { country_code: "CO", country_prefix: "57" },
      { country_code: "VE", country_prefix: "58" },
      { country_code: "BO", country_prefix: "591" },
      { country_code: "EC", country_prefix: "593" },
      { country_code: "PY", country_prefix: "595" },
      { country_code: "UY", country_prefix: "598" },
      { country_code: "AU", country_prefix: "61" },
      { country_code: "CN", country_prefix: "86" },
      { country_code: "ES", country_prefix: "34" },
      { country_code: "IT", country_prefix: "39" },
      { country_code: "DE", country_prefix: "49" },
      { country_code: "FR", country_prefix: "33" },
      { country_code: "PT", country_prefix: "351" },
      { country_code: "CH", country_prefix: "41" },
      //{ country_code: "EN", country_prefix: "" },
    ],
  }),

  methods: {
    onUpdatePhone: function(payload) {
      this.$emit('change',  payload)
    },
  },

  components: {
    VuePhoneNumberInput,
  },

  beforeMount() {
    if(this.model) {
      const prefixToRemove = '+' + this.countryPrefix + ' '
      const stringWithPrefix = this.model

      if (stringWithPrefix.startsWith(prefixToRemove)) {
        this.phone = stringWithPrefix.substring(prefixToRemove.length)
      }
    }
  },

  mounted() {
    this.vuePhone.props.translations.phoneNumberLabel = this.label;
  },

  computed: {
    /*countryCode (code) {
      const country = this.countriesWithPrefix.find(country => country.country_prefix === code);
      return country ? country.country_code : 'AR';
    },*/

    countryPrefix () {
      const country = this.countriesWithPrefix.find(country => country.country_code === this.areaCode);
      return country ? country.country_prefix : 54;
    },

    countryCode () {
      return this.areaCode ?? 'AR'
    }
  },
}
</script>