<template>
  <v-col>
    <v-row justify="center">
      <span style="font-size: 0.875rem; display: flex; align-items: center; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
        <v-icon class="color-primary" size="15"> mdi-exclamation-thick </v-icon>
        {{ example }}
      </span>
    </v-row>
  </v-col>
</template>

<script>
//import '@mdi/font/css/materialdesignicons.css';

export default {
  name: 'ExampleIndicator',

  props: {
    example: {
      type: String,
      required: true,
    },
  },

  components: {},

  data: () => ({}),

  beforeMount() {},

  mounted() {},

  methods: {}
}
</script>

<style scoped>
.color-primary{
  color: var(--color-primary) !important;
}
</style>