<template>
  <v-container fluid>
    <v-row >
      <v-col class="my-0">
        <v-text-field v-model="searchQuery" label="Filtrar" outlined class="my-0"></v-text-field>
      </v-col>
    </v-row>
    <v-row class="my-0">
      <v-col v-for="system in filteredSystems" :key="system.id" cols="12" sm="6" md="4" lg="3" >
        <exchange-card :system="system" :systemList="systemList" :chips="getSystemsWithRateTo(system)" :rates="getSystemRates(system)" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import System from "../../model/System";
import ExchangeCard from "./ExchangeCard";
import Rate from "../../model/Rate";

export default {
  name: 'RatesCardSection',

  props: {
    rates: {
      type: Array,
      required: true,
      validator: function (value) {
        return value.every(item => item instanceof Rate);
      },
    },

    systemList: {
      type: Array,
      required: true,
      validator: function (value) {
        return value.every(item => item instanceof System);
      },
    },
  },

  components: {
    ExchangeCard,
  },

  data: () => ({
    searchQuery: '',
  }),

  beforeMount() {},

  methods: {
    getSystemsWithRateTo(system) {
      const ids = this.getSystemToValues(system)
      return this.systemList.filter(system => ids.includes(system.id))
    },

    getSystemToValues(system) {
      return this.rates.filter(rate => rate.systemFrom.id === system.id).map(rate => rate.systemTo.id);
    },

    getSystemRates(system) {
      return this.rates.filter(rate => rate.systemFrom.id === system.id)
    }
  },

  computed: {
    filteredSystems() {
      const query = this.searchQuery.toLowerCase();
      return this.systemList.filter(
          (system) =>
              system.name.toLowerCase().includes(query) ||
              system.currency.abbreviation.toLowerCase().includes(query)
      );
    },
  },
}
</script>