<template>
  <v-row align="start"
         justify="center"
         align-content="center"
         class="mx-0"
         v-bind:class="{ 'px-0': mobile }"
  >
    <slot></slot>
  </v-row>
</template>

<script>
export default {
  name: "WebRow",

  data: () => ({
    mobile: false,
  }),

  beforeMount() {
    this.mobile = this.isMobile;
  },

  watch: {
    "$store.state.window.mobile"(nv) {
      this.mobile = nv;
    },
  },

  computed: {
    isMobile () {
      return this.$store.state.window.mobile;
    },
  },
}
</script>