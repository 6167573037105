<template>
  <v-app class="background-color-primary-shade3">
    <dynamic-app-bar/>
    <v-main>
      <router-view/>
    </v-main>
    <dynamic-footer/>
  </v-app>
</template>

<script>
  import './main.css';
  import DynamicContainer from './components/Common/DynamicContainer';
  import DynamicAppBar from './components/Common/DynamicAppBar';
  import DynamicFooter from "./components/Common/DynamicFooter";

  export default {
    name: 'App',

    components: {
      DynamicContainer,
      DynamicAppBar,
      DynamicFooter,
    },

    data: () => ({
      window: {
        width: 0,
        height: 0,
        mobile: false,
      },
    }),

    mounted() {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
    },

    destroyed() {
      window.removeEventListener('resize', this.handleResize);
    },

    methods: {
      handleResize() {
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;

        this.window.mobile = this.window.width <= 1264;

        this.$store.commit('setWindowWidth', window.innerWidth);
        this.$store.commit('setWindowHeight', window.innerHeight);
        this.$store.commit('setWindowMobile', this.window.mobile);
      },
    },
  };
</script>