<template>
  <v-row class="fill-height">
    <v-col align="center" cols="12">
      <v-progress-circular
          id="loading-indicator"
          :size="70"
          :width="7"
          indeterminate
          color="primary"
      ></v-progress-circular>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Loader",

  watch: {
    "$store.state.window.height"(nv) {
      let indicator = document.getElementById('loading-indicator');
      let nh = nv - 150;
      indicator.style.height = nh + 'px';
    },
  },
}
</script>