<template>
  <div>
    <validation-provider
        v-slot="{ errors }"
        :name="field_label"
        :rules="field_rules"
    >
      <v-text-field
          v-model="field_model"
          :error-messages="errors"
          :label="field_label"
          outlined
          dense
          @change="onUpdateValue"
          :id="field_id"
          :hint="hint"
      ></v-text-field>
    </validation-provider>
  </div>
</template>

<script>
import {extend, setInteractionMode, ValidationProvider} from 'vee-validate'
import {numeric, required} from 'vee-validate/dist/rules'

setInteractionMode('eager')

extend('required', {
  ...required,
  message: 'Este campo es requerido',
})

extend('noSpecialChars', {
  validate(value) {
    let specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return !specialChars.test(value);
  },
  message: 'Ingresar sólo números'
});

extend('numeric', {
  ...numeric,
  message: 'Ingresar sólo números',
})

export default {
  name: "ValidatedOutlinedTextInput",

  components: {
    ValidationProvider,
  },

  props: {
    label: String,
    model: String,
    noSpecialChars: Boolean,
    numeric: Boolean,
    required: Boolean,
    id: String,
    hint: String,
  },

  data: () => ({
    field_label: "",
    field_model: "",
    field_rules: "",
    field_id: null,
  }),

  mounted() {
    this.field_label = this.label;
    this.field_model = this.model;
    this.field_id = this.id;
    if(this.required) {
      this.field_rules = 'required';
    }
    if(this.noSpecialChars) {
      this.field_rules += '|noSpecialChars';
    }
    if(this.numeric) {
      this.field_rules += '|numeric';
    }
  },

  methods: {
    onUpdateValue: function(payload) {
      this.$emit('change', payload)
    },
  },
}
</script>
<style>
input::placeholder {
  font-weight: bold;
  opacity: .0;
  color: transparent;
}
</style>