import Currency from "./Currency";
import ShipmentType from "./ShipmentType";

export default class System {
    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.roundPrecision = data.round_precision;
        this.tax = data.tax;
        this.logoUrl = data.logo_url;
        this.order = data.order;

        this.canReceive = data.can_receive;
        this.minAmountReceive = data.min_amount_receive;
        this.maxAmountReceive = data.max_amount_receive;
        this.canSend = data.can_send;
        this.minAmountSend = data.min_amount_send;
        this.maxAmountSend = data.max_amount_send;

        this.currency = new Currency(data.currency);

        this.shipmentTypes = data.shipments_type.map(type => new ShipmentType(type));

        this.immediateAccreditation = data.immediate_accreditation;
    }

    static createSystemsFromArray(dataArray) {
        const systemsSet = new Set(dataArray.map(data => new System(data)));
        return Array.from(systemsSet).sort((a, b) => a.order - b.order);
    }
}