<template>
  <v-scale-transition>
    <v-card class="pa-5 rounded-lg" outlined>
      <div>
        Datos para la transferencia
      </div>
      <br>
      <div v-if="accountsData.length > 1">
        <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Cuenta"
        >
          <v-select
              :items="accountsData"
              v-model="localAccountData"
              label="Cuenta"
              item-text="name"
              item-value="id"
              class="mx-auto"
              dense
              outlined
              required
              :error-messages="errors"
              @change="accountSelected"
          ></v-select>
        </validation-provider>
      </div>
      <div v-if="localAccountData !== null" :key="redrawKey" >
        <validated-outlined-text-input
            @change="transferBankUpdated"
            label="Banco"
            :model="localAccountData.transferBank"
            required
        />
        <validated-outlined-text-input
            @change="transferHolderUpdated"
            label="Titular de la cuenta"
            :model="localAccountData.transferHolder"
            required
        />
        <validated-outlined-text-input
            @change="transferAliasUpdated"
            label="Alias"
            v-model="localAccountData.transferAlias"
            required />
        <validated-outlined-text-input
            @change="transferCuitUpdated"
            label="CUIT"
            :model="localAccountData.transferCuit"
            noSpecialChars
            numeric
            required
        />
        <validated-outlined-text-input
            @change="transferCbuUpdated"
            label="CBU/CVU"
            :model="localAccountData.transferCbu"
            noSpecialChars
            numeric
            required
        />
        <validated-outlined-text-input
            @change="transferAccountNumberUpdated"
            label="Nro. de cuenta"
            :model="localAccountData.transferAccountNumber"
            hint="No es necesiario si tenés Ualá o Mercado Pago"
            noSpecialChars
            numeric
        />
      </div>
    </v-card>
  </v-scale-transition>
</template>

<script>

import {ValidationProvider} from "vee-validate";
import AccountData from "../../../model/AccountData";
import ValidatedOutlinedTextInput from "../../Form/ValidatedOutlinedTextInput";

export default {
  name: "BankTransferForm",

  components: {
    ValidationProvider,
    ValidatedOutlinedTextInput,
  },

  props: {
    accountsData: {
      type: Array,
      required: true,
      validator: function (value) {
        return value.every(item => item instanceof AccountData);
      },
    },
  },

  data: () => ({
    localAccountData: null,
    redrawKey: 0,
  }),

  beforeMount() {
    if(this.accountsData.length === 1) {
      this.updateAccount(this.accountsData[0])
    } else if (this.accountsData.length === 0) {
      this.updateAccount(AccountData.Blank())
    }
  },

  mounted() {},

  methods: {
    accountSelected(payload) {
      this.updateAccount(this.accountsData.find(accountData => accountData.id === payload))
      this.redrawKey++
    },

    updateAccount(data) {
      this.localAccountData = data
      this.$store.commit('updateStoreValue', { variable: 'account_data', value: this.localAccountData  });
    },

    transferBankUpdated(payload) {
      this.localAccountData.transferBank = payload
      this.$store.commit('updateStoreValue', { variable: 'account_data', value: this.localAccountData  });
    },

    transferHolderUpdated(payload) {
      this.localAccountData.transferHolder = payload
      this.$store.commit('updateStoreValue', { variable: 'account_data', value: this.localAccountData  });
    },

    transferAliasUpdated(payload) {
      this.localAccountData.transferAlias = payload
      this.$store.commit('updateStoreValue', { variable: 'account_data', value: this.localAccountData  });
    },

    transferCuitUpdated(payload) {
      this.localAccountData.transferCuit = payload
      this.$store.commit('updateStoreValue', { variable: 'account_data', value: this.localAccountData  });
    },

    transferCbuUpdated(payload) {
      this.localAccountData.transferCbu = payload
      this.$store.commit('updateStoreValue', { variable: 'account_data', value: this.localAccountData  });
    },

    transferAccountNumberUpdated(payload) {
      this.localAccountData.transferAccountNumber = payload
      this.$store.commit('updateStoreValue', { variable: 'account_data', value: this.localAccountData  });
    },
  },

  computed: {}
}
</script>