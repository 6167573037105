export default class Rate {
    constructor(data, systemFrom, systemTo) {
        this.id = data.id;
        this.price = data.price;
        this.slug = data.slug;
        this.type = data.type;
        this.typeLabel = data.type_label;
        this.systemFrom = systemFrom;
        this.systemTo = systemTo;
    }

    static getAllUniqueRates(ratesData, systemList) {
        const rateList = [];
        const uniqueRateIds = new Set();

        ratesData.forEach(rateData => {
            const systemFromId = rateData.system_from_id;
            const systemToId = rateData.system_to_id;

            const systemFrom = systemList.find(s => s.id === systemFromId);
            const systemTo = systemList.find(s => s.id === systemToId);

            if (systemFrom && systemTo) {
                const rate = new Rate(rateData, systemFrom, systemTo);

                if (!uniqueRateIds.has(rate.id)) {
                    uniqueRateIds.add(rate.id);
                    rateList.push(rate);
                }
            }
        });

        return rateList;
    }

    convertPrice(amount) {
        //let amountAfterTaxes = amount - this.systemFrom.tax;
        let option = this.type;
        if (Number(option) === 1) {
            return amount * this.price - this.systemFrom.tax;
        } else {
            return amount / this.price - this.systemFrom.tax;
        }
    }

    unConvertPrice(amount) {
        let amountAfterTaxes = amount + this.systemFrom.tax;
        let option = this.type;
        if (Number(option) === 1) {
            return amountAfterTaxes / this.price;
        } else {
            return amountAfterTaxes * this.price;
        }
    }

    buildExampleText() {
        //TODO: ajustar esto
        const amount = this.systemFrom.minAmountReceive
        const transformed = this.convertPrice(amount).toFixed(2)
        return 'Vos enviás ' + amount + ' ' + this.systemFrom.currency.abbreviation + ' y recibís ' + transformed + ' ' + this.systemTo.currency.abbreviation;
    }

    buildExampleEquation() {
        //TODO: ajustar esto
        const amount = this.systemFrom.minAmountReceive
        const transformed = this.convertPrice(amount).toFixed(2)
        return '(' + this.minAmountToReceive() + ' * ' + this.price + ') - ' + this.systemFrom.tax + ' = ' + transformed;
    }

    minAmountToReceive() {
        return Math.max(this.systemFrom.minAmountReceive, this.unConvertPrice(this.systemTo.minAmountSend))
    }

    maxAmountToReceive() {
        return Math.min(this.systemFrom.maxAmountReceive, this.unConvertPrice(this.systemTo.maxAmountSend))
    }

    minAmountToSend() {
        return this.convertPrice(this.minAmountToReceive())
    }

    maxAmountToSend() {
        return this.convertPrice(this.maxAmountToReceive())
    }

    initialAmount() {
        return this.minAmountToReceive()
    }

    validateMinAndMax(amount) {
        return amount >= this.minAmountToReceive() &&
            amount <= this.maxAmountToReceive()
    }

    validatePrecision(amount) {
        return (this.convertPrice(amount) % this.systemTo.roundPrecision) < 1e-2
    }

    validateInput(amount) {
        return this.validateMinAndMax(amount) && this.validatePrecision(amount)
    }

    validateResult(result) {
        return result >= this.minAmountToSend() &&
            result <= this.maxAmountToSend() &&
            (result % this.systemTo.roundPrecision) < 1e-2
    }

    getRoundedUpResult(result) {
        return ~~(result / this.systemTo.roundPrecision) * this.systemTo.roundPrecision + this.systemTo.roundPrecision
    }

    getRoundedDownResult(result) {
        return ~~(result / this.systemTo.roundPrecision) * this.systemTo.roundPrecision
    }

    getRoundedUpOption(result) {
        return this.unConvertPrice(this.getRoundedUpResult(result))
    }

    getRoundedDownOption(result) {
        return this.unConvertPrice(this.getRoundedDownResult(result))
    }

    getAmountSuggestions(amount) {
        let result = this.convertPrice(amount)
        let rounded_down_option = this.getRoundedDownOption(result);
        let rounded_up_option = this.getRoundedUpOption(result);

        let suggestions = []

        if(this.validateInput(rounded_down_option)){
            suggestions.push(rounded_down_option)
        }

        if(this.validateInput(rounded_up_option)){
            suggestions.push(rounded_up_option)
        }

        return suggestions
    }

    getMinAmount() {
        if(this.systemTo.roundPrecision > 0) {
            return this.getRoundedUpOption(this.convertPrice(this.initialAmount()))
        }
        return this.initialAmount()
    }
}