<template>
  <v-carousel :height="height"  hide-delimiter-background color="red" dot-color="blue">
    <template v-slot:prev="{ on, attrs }">
      <v-btn dark color="primary" v-bind="attrs" v-on="on" fab height="40" width="40" elevation="0">
        <v-icon size="30">mdi-chevron-left</v-icon>
      </v-btn>
    </template>
    <template v-slot:next="{ on, attrs }">
      <v-btn dark color="primary" v-bind="attrs" v-on="on" fab height="40" width="40" elevation="0">
        <v-icon size="30">mdi-chevron-right</v-icon>
      </v-btn>
    </template>
    <slot></slot>
  </v-carousel>
</template>

<script>
import '../../main.css';
import {integer} from "vee-validate/dist/rules";

export default {
  name: 'CustomCarousel',

  props: {
    height: {
      type: integer,
      required: true
    },
  },
}
</script>
<style>
.v-carousel__controls__item{
  color: var(--color-primary) !important
}
</style>