<template>
  <div>
    <v-row
        class="ma-0 pa-0"
        style="position: relative;"
    >
      <v-col
          align="center"
          cols="12"
          lg="6"
      >
        <amount-input
            :model="amount"
            :label="selectedSystem.currency.abbreviation"
            :min="min"
            :max="max"
            required
            no-special-chars
            numeric
            @amountUpdated="amountUpdated"
            @amountBlurred="amountBlurred"
        />
      </v-col>
      <v-col
          align="center"
          cols="12"
          lg="6"
      >
        <system-select
            :selected-system="selectedSystem"
            :system-list="systemList"
            @selectedSystemUpdated="selectedSystemUpdated"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import System from "../../model/System";
import AmountInput from "../../components/fields/AmountInput";
import SystemSelect from "../amounts/SystemSelect";

export default {
  name: 'SystemForm',

  props: {
    systemList: {
      type: Array,
      required: true,
      validator: function (value) {
        return value.every(item => item instanceof System);
      },
    },

    selectedSystem: {
      type: System,
      required: true,
    },

    amount: {
      type: Number,
      required: false,
    },

    min: {
      type: Number,
      required: true,
    },

    max: {
      type: Number,
      required: true,
    },
  },

  components: {
    AmountInput,
    SystemSelect,
  },

  data: () => ({}),

  beforeMount() {},

  mounted() {},

  methods: {
    selectedSystemUpdated: function(payload) {
      this.$emit('selectedSystemUpdated', payload)
    },

    amountUpdated: function(payload) {
      this.$emit('amountUpdated', payload)
    },

    amountBlurred: function() {
      this.$emit('amountBlurred', '')
    },
  }
}
</script>