<template>
  <v-col>
    <v-row v-if="tax > 0" justify="center">
      <span style="font-size: 0.875rem">
        <v-icon class="color-primary" size="15"> mdi-exclamation-thick </v-icon>
        Recuerde que esta operación contine un costo fijo de {{ tax }} {{ abbreviation }}
      </span>
    </v-row>
    <v-row v-else justify="center">
      <span style="font-size: 0.875rem">
        <v-icon class="color-primary" size="15"> mdi-exclamation-thick </v-icon>
        Esta operación no cuenta con costo fijo
      </span>
    </v-row>
  </v-col>
</template>

<script>
//import '@mdi/font/css/materialdesignicons.css';

export default {
  name: 'TaxIndicator',

  props: {
    tax: {
      type: Number,
      required: true,
    },

    abbreviation: {
      type: String,
      required: true,
    },
  },

  components: {},

  data: () => ({}),

  beforeMount() {},

  mounted() {},

  methods: {}
}
</script>

<style scoped>
.color-primary{
  color: var(--color-primary) !important;
}
</style>