<template>
  <div class="rectangular-component padding-border background-color-primary-shade6 d-flex text-center">
    <v-col
        align="start"
        justify="start">
      <h4 class="title color-primary">Usuario: {{ user }}</h4>
      <h4 class="title color-primary">Fecha: {{ date }}</h4>
      <p class="content color-primary">{{ content }}</p>
    </v-col>
  </div>
</template>

<script>
export default {
  name: 'Opinion',

  props: {
    user: {
      type: String,
      required: true
    },

    date: {
      type: String,
      required: true
    },

    content: {
      type: String,
      required: true
    }
  }
};
</script>

<style>
.rectangular-component {
  /* Define your custom styles here */
}

.padding-border {
  /* Define your custom styles here */
}

.d-flex {
  /* Define your custom styles here */
}

.text-center {
  /* Define your custom styles here */
}

.title {
  /* Define your custom styles here */
}

.content {
  /* Define your custom styles here */
}
</style>